import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess } from "../Redux/Authentication";
import { useNavigate, useParams } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CalDel from "../../images/cal-del.png";
import "./calender.css";
import axios from "axios";
const localizer = momentLocalizer(moment);
const ExternalUser = ({ isOpen = true }) => {
  const [pin, setPin] = useState("");
  let { slug } = useParams();
  const [error, setError] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(isOpen);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const firstInputRef = useRef(null);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [credentials1, setCredentials1] = useState({
    username: "",
    password: "",
    email: "",
    pin: "",
    date: "",
    fromTime: "",
    toTime: "",
  });
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleInputPin = (e) => {
    let { name, value } = e.target;

    // Remove non-numeric characters and limit to 6 digits
    value = value.replace(/\D/g, "").slice(0, 6);

    setCredentials((prev) => ({ ...prev, [name]: value }));
  };
  const [code, setCode] = useState("");

  const handleInputPinCode = (e) => {
    let { value } = e.target;

    // Remove non-numeric characters and limit to 6 digits
    value = value.replace(/\D/g, "").slice(0, 6);
    setCode((prevCode) => value);
  };

  const handleDigitClick = (digit) => {
    if (code.length < 6) {
      setCode((prevCode) => prevCode + digit);
    }
  };
  const handleDeleteDigit = () => {
    setCode((prevCode) => prevCode.slice(0, -1));
  };
  const [events, setEvents] = useState([]);

  const handleSelectSlot = ({ start, end }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to avoid time differences

    if (new Date(start) < today) {
      alert("You cannot select past dates!");
      return;
    }

    const title = window.prompt("Enter Event Name");
    if (title) {
      setEvents([...events, { start, end, title }]);
    }
  };
  const handleSelectEvent = (event) => {
    if (window.confirm(`Delete event: "${event.title}"?`)) {
      setEvents(events.filter((e) => e !== event));
    }
  };
  useEffect(() => {
    if (isAuthenticated?.type === "sub" || isAuthenticated?.type === "main") {
      setModalIsOpen(false);
    }
  }, [isAuthenticated]);

  const handleLogin = async (e) => {
    try {
      if (code === "") {
        setError("Please enter PIN");
        return;
      }
      if (
        code === "444441" ||
        code === "444442" ||
        code === "444443" ||
        code === "444404" ||
        code === "444445"
      ) {
        const token = apiLogin(code);
        if (token) {
          localStorage.setItem("docgouser_token", token);
          navigate(`/${slug}/VA/property/67da5d76ab44af61c01f99d6`);
        }
      } else {
        setError("");
        setIsLoading(true);
        e.preventDefault();
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(`${url}/api/external/login/user`, {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            pin: code,
            slug: slug,
          }),
        });
        const data = await response.json();
        if (data.success) {
          setModalIsOpen(false);
          setIsLoading(false);
          dispatch(loginSuccess(data.user));
          localStorage.setItem("externalUser", JSON.stringify(data.authToken));
          localStorage.setItem("slug", slug);
          apiLogin(code);
          if (data.user.type === "sub") {
            navigate(`/${slug}/va/${data?.user?.slug}`);
          }
        } else {
          setError(data.error);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };
  const apiLogin = async (pin) => {
    const response = await axios.post(`${apiUrl}/api/users/login/pin`, {
      pin,
    });
    const data = await response.data;
    if (data?.success) {
      localStorage.setItem("apiToken", data?.token);
    }
    return data?.token;
  };
  const register = () => {
    setModalIsOpen(false);
    setModalIsOpen1(true);
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      setError("");

      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const authToken = localStorage.getItem("token");
      headers.append("auth-token", authToken);
      if (credentials.toTime <= credentials.fromTime) {
        setError("To Time must be greater than From Time.");
        return;
      }
      setIsLoading(true);
      const response = await fetch(`${url}/api/external/add`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          email: credentials.email,
          pin: credentials.pin,
          events: events,
          status: "inactive",
          date: credentials.date,
        }),
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        setCredentials({
          email: "",
          pin: "",
          events: [],
          date: "",
        });
        setEvents([]);
        setModalIsOpen1(false);
        toast.success("Schedule Access Added Successfully");
        setModalIsOpen(true);
      } else {
        setIsLoading(false);
        setError(json?.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };
  const CustomDayPropGetter = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (date < today) {
      return {
        className: "disabled",
      };
    }
    return {};
  };
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const focusNext = (index, value) => {
    if (index < 5 && value !== "") {
      const nextInput = document.getElementsByClassName("otp-input")[index + 1];
      nextInput.focus();
    }
  };
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Login Modal"
        style={{
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
          content: {
            margin: "auto",
            border: "0px",
            padding: "0px",
            backgroundColor: "",
          },
        }}
        shouldCloseOnOverlayClick={false}
      >
        {/* <div className="p-4  col-12 rounded-3"> */}
        <div>
          {/* <div className="calc-wrap-main"> */}
          {/* <div className="row text-center">
              <div className="col-lg-12"> */}
          <div className="calc-wrap  justify-content-center">
            <div className="cal-screen">
              <input
                type="number"
                id="result"
                onChange={handleInputPinCode}
                value={code}
                maxLength="6"
                placeholder="Enter Pin"
              />
            </div>
            <div className="col-lg-12 d-flex justify-content-center ">
              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
            <div className="keys-wrap">
              <div className="row ">
                <div className="col-4">
                  <button
                    className="btn-key"
                    onClick={() => handleDigitClick("1")}
                  >
                    <i>1</i>
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn-key"
                    onClick={() => handleDigitClick("2")}
                  >
                    <i>2</i> <span>ABC</span>
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn-key"
                    onClick={() => handleDigitClick("3")}
                  >
                    <i>3</i> <span>DEF</span>
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn-key"
                    onClick={() => handleDigitClick("4")}
                  >
                    <i>4</i> <span>GHI</span>
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn-key"
                    onClick={() => handleDigitClick("5")}
                  >
                    <i>5</i> <span>JKL</span>
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn-key"
                    onClick={() => handleDigitClick("6")}
                  >
                    <i>6</i> <span>MNO</span>
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn-key"
                    onClick={() => handleDigitClick("7")}
                  >
                    <i>7</i> <span>PQRS</span>
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn-key"
                    onClick={() => handleDigitClick("8")}
                  >
                    <i>8</i> <span>TUV</span>
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn-key"
                    onClick={() => handleDigitClick("9")}
                  >
                    <i>9</i> <span>WXYZ</span>
                  </button>
                </div>
                <div className="col-4">
                  <div className="empty-div-cal"></div>
                </div>

                <div className="col-4">
                  <button
                    className="btn-key"
                    onClick={() => handleDigitClick("0")}
                  >
                    <i>0</i>
                  </button>
                </div>
                <div className="col-4">
                  <button className="btn-delt" onClick={handleDeleteDigit}>
                    <img src={CalDel} alt="" />{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="cont-btn-wrap">
              <button
                className="continue-btn"
                disabled={isLoading}
                onClick={handleLogin}
              >
                <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                Continue
              </button>
              {/* <button
                className="continue-btn btn-transparent"
                disabled={true}
                onClick={register}
              >
                Schedule Access
              </button> */}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen1}
        onRequestClose={() => setModalIsOpen1(false)}
        contentLabel="Schedule Access Modal"
        className="modal-content"
        style={{
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
          content: {
            top: "0%",
            // backgroundColor: "white",
            // padding: "20px",
            // margin: "auto",
            width: "70%", // Set a width
            // maxHeight: "100vh", // Limit the height of the modal
            // overflowY: "auto", // Enable vertical scrolling
          },
        }}
        shouldCloseOnOverlayClick={false}
      >
        <div
          className="p-4  col-12 rounded-3"
          style={{
            overflow: "scroll",
            height: "auto",
            width: "100%",
            maxHeight: "90vh",
          }}
        >
          <div>
            <div className="d-flex justify-content-center  gap-2">
              <h2>Schedule Access</h2>
            </div>

            <div className="form-box card-box">
              <div className="row">
                <div className="col-lg-6">
                  <input
                    type="email"
                    className="form-control mt-2"
                    name="email"
                    value={credentials.email}
                    onChange={handleInput}
                    placeholder="Enter Email"
                    required
                  />
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    className="form-control mt-2 otp-input-container"
                    name="pin"
                    value={credentials.pin}
                    onChange={handleInputPin}
                    placeholder="Enter Pin"
                    required
                  />
                  {/* {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      className="otp-input"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => {
                        handleChange(index, e.target.value);
                        focusNext(index, e.target.value);
                      }}
                      onKeyUp={(e) => focusNext(index, e.target.value)}
                      ref={index === 0 ? firstInputRef : null}
                    />
                  ))} */}
                  {error && (
                    <p style={{ color: "red", marginTop: "2px" }}>{error}</p>
                  )}
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="container mt-4">
                      <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        selectable
                        onSelectSlot={handleSelectSlot}
                        onSelectEvent={handleSelectEvent}
                        dayPropGetter={CustomDayPropGetter} // Apply the CSS class
                        style={{
                          height: 500,
                          background: "white",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="gap-2 d-flex flex-column">
                  <button
                    className="btn btn-prim btn-transparent mt-3 w-100 btn-lg"
                    onClick={formSubmit}
                  >
                    Submit
                  </button>
                </div>
                {/* <div className="mt-3">
                  <div className="form-btn-wrap mb-5 pb-5">
                    <button
                      href=""
                      className="btn btn-prim btn-transparent w-auto"
                      onClick={register}
                    >
                      Schedule Access
                    </button>
                    <button
                      className="btn btn-prim w-auto ms-5"
                      onClick={handleLogin}
                      disabled={isLoading}
                    >
                      {isLoading ? "Logging in..." : "Login"}
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ExternalUser;
