import React, { useState, useRef, useEffect } from "react";
import "./Call.css";
import ReactPaginate from "react-paginate";
import { StringeeUtil, StringeeClient } from "stringee";

import DecVideCall from "../../images/dec-video-call.png";
import AttendVideoCall from "../../images/attend-video.png";
import { Link, useNavigate } from "react-router-dom";
import SwtchVideo from "../../assets/new-design/unmute_vdo_icon.svg";
import MuteSwitch from "../../assets/new-design/muted_mic_icon.svg";
import UnmuteSwitch from "../../assets/new-design/unmuted_mic_ico n.svg";
import UnmuteVideo from "../../assets/new-design/muted_vdo_icon.svg";
import AttendVideoIcon from "../../images/attend-video.png";
import DeclineIcon from "../../images/decline-call.png";
import Modal from "react-modal";
import ringTone from "../../assets/ringcall.mp3";
import backgroundMusicClick from "../../assets/click.mp3";
import IncomingCallee from "../../assets/incoming-call.png";
import MissedCall from "../../assets/missed-call.png";
import TotalCall from "../../assets/phone-call.png";
import LongestTime from "../../assets/longest-time.png";
import AverageTime from "../../assets/average-time.png";
import Screenshot from "../../assets/screenshot-icon.png";
import FlipCamera from "../../assets/flip-camera.png";
import html2canvas from "html2canvas";
import io from "socket.io-client";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const VideoAttendant = ({ slug }) => {
  //   let { slug } = useParams();
  slug = slug.toLowerCase();

  let navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [stringeeClient, setStringeeClient] = useState(null);
  const [call, setCall] = useState(null);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [sdkVersion, setSdkVersion] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [loggedUserId, setLoggedUserId] = useState("");
  const [muted, setMuted] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [connectionDestroy, setConnectionDestroy] = useState(false);
  const [isCallActive, setIsCallActive] = useState(false);
  const [isAttendantBusy, setIsAttendantBusy] = useState(false);
  const isAttendantBusyRef = useRef(isAttendantBusy);
  const [isAttendantModal, setIsAttendantModal] = useState(true);
  const isAttendantModalRef = useRef(isAttendantModal);
  const [incomingCalls, setCallQueue] = useState([]);
  const callCountRef = useRef(0);
  const callDuration = useRef(0);
  const [callQueue, setCallingQueue] = useState([]);
  const [callerId, setCallerId] = useState("");
  const [maxDuration, setMaxDuration] = useState("");
  const [avgDuration, setAvgDuration] = useState("");
  const [missedCalls, setMissedCalls] = useState("");
  const [callersList, setCallersList] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 5;
  const [backgroundAudio] = useState(new Audio(ringTone));
  const [backgroundAudioClick] = useState(new Audio(backgroundMusicClick));
  const leaveCallButtonRef = useRef(null);
  const ringCall = useRef(null);
  const clickButton = useRef(null);
  const clickJoin = useRef(null);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  useEffect(() => {
    isAttendantBusyRef.current = isAttendantBusy;
  }, [isAttendantBusy]);
  useEffect(() => {
    isAttendantModalRef.current = isAttendantModal;
  }, [isAttendantModal]);
  // useEffect(() => {
  //   // Update the ref value whenever incomingCalls changes
  //   callCountRef.current = incomingCalls.length;
  // }, [incomingCalls]);
  useEffect(() => {
    setSdkVersion(
      StringeeUtil.version().version +
        "_build_" +
        StringeeUtil.version().build +
        "_sample_1"
    );
    getAccessToken();
  }, []);

  const [showIncomingCalls, setShowIncomingCalls] = useState(false);
  const [showHistoryCalls, setShowHistoryCalls] = useState(false);
  const handleQueueUserListClick = () => {
    setShowIncomingCalls(true);
    setShowHistoryCalls(false);
  };

  const handleUserHistoryListClick = () => {
    setShowHistoryCalls(true);
    setShowIncomingCalls(false);
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };

  function updateElapsedTime() {
    setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
  }
  const intervalIdRef = useRef(null);
  const getAccessToken = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/auth/get-token?userId=${"storagelok3r" + slug}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      const json = await response.json();
      if (json.success) {
        setAccessToken(json.token);
        handleLogin(json.token, true);
        setCallerId(json.token);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateQueueData = async (call) => {
    setTimeout(() => {
      updateCall(call.callId, "busy", 0);
      let number = parseInt(callCountRef.current);
      socket.emit("callResponse", {
        user: call.fromNumber,
        duration: callDuration.current,
        number,
      });
    }, 1000);
  };
  // update call status
  const updateCall = async (id, status, duration) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/call/updateCall`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id: id,
          status: status,
          duration: duration,
        }),
      });

      const json = await response.json();
      if (json.success) {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleScreenshot = () => {
    const localVideo = localVideoRef.current;
    const remoteVideo = remoteVideoRef.current;

    // Freeze styles and positioning
    localVideo.style.transition = "none";
    localVideo.style.position = "absolute";
    localVideo.style.top = "1%";
    localVideo.style.right = "1%";
    remoteVideo.style.transition = "none";

    // Capture the element with specific scale
    const element = document.body; // Or the specific element you want to capture

    html2canvas(element, {
      useCORS: true,
      scale: 3, // Increase scale for better accuracy
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "screenshot.png";
      link.click();

      // Re-enable transitions if necessary
      localVideo.style.transition = "";
      localVideo.style.transform = "";
    });
  };
  const handleLogin = (token, modal) => {
    const client = new StringeeClient();
    client.on("connect", function () {});

    client.on("authen", function (res) {
      console.log("response", res);
      if (res.r === 0) {
        setLoggedUserId(res.userId);
      } else {
        setLoggedUserId(res.message);
      }
    });

    client.on("disconnect", function () {
      // alert("disconnect");
    });

    client.on("incomingcall", function (incomingcall) {
      if (isAttendantBusyRef.current) {
        // Add incoming call to the queue
        setCallQueue((prevQueue) => {
          // Check if the incoming call is already in the queue
          const callExists = prevQueue.some(
            (call) => call.callId === incomingcall.callId
          );
          if (!callExists) {
            getCallData();
            // callDuration.current=avgDuration;
            callCountRef.current = parseInt(callCountRef.current) + 1;
            updateQueueData(incomingcall);
            return [...prevQueue, incomingcall];
          }
          return prevQueue; // Return the unchanged queue if the call already exists
        });

        // Notify the user that the call is added to the queue
        // incomingcall.reject(function (res) {
        //   console.log("Call rejected and added to queue", res);
        // });
      } else {
        // Proceed with handling the new call
        // setCallQueue([incomingcall]);
        setCallQueue([]);
        setIsAttendantBusy(true);
        handleIncomingCall(incomingcall, modal);
        callCountRef.current = 0;
        console.log("avg duration", avgDuration);
      }
    });

    // Function to handle incoming calls

    client.on("requestnewtoken", function () {
      //please get new access_token from YourServer and call:
      //client.connect(new_access_token);
    });

    setStringeeClient(client);
    client.connect(token);
    // client.on("callInfo", function (info) {
    //   if (info && info.action === "hangup") {
    //     alert("call info");
    //   }
    // });
  };

  const handleIncomingCall = async (incomingcall, modal) => {
    // Set the current call to the incoming call
    setCall(incomingcall);
    // Handle the call connection
    await callConnection(incomingcall, modal);
    // Set call as active
    setIsCallActive(true);
    // Show call interface
    if (isAttendantModalRef.current) {
      setCallQueue([]);
      setIsOpen(true);
    } else {
      setConnectionDestroy(true);
      setTimeout(() => {
        if (clickJoin.current) {
          clickJoin.current.click();
        }
      }, 1000);
    }

    // Auto answer if possible
    if (ringCall.current) {
      ringCall.current.click();
    }
    // Handle incoming call events
    incomingcall.ringing(function (res) {});
  };
  const callConnection = async (newCall, modal) => {
    console.log("new call", newCall);
    newCall.on("addlocalstream", (stream) => {
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }
    });
    newCall.on("addremotestream", (stream) => {
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = stream;
      }
    });
    newCall.on("error", function (info) {
      console.log("on error: " + JSON.stringify(info));
    });

    newCall.on("signalingstate", function (state) {
      console.log(state.code, "state.code");

      if (state.code === 3) {
        // call connected
        setIsCallActive(true);
        console.log("Call state set to true");
      } else if (state.code === 5) {
        console.log("Call is busy");
      } else if (state.code === 6) {
        // Call declined
        // Close the video tab
        setConnectionDestroy(false);
        setIsCallActive(false);
      }
    });

    newCall.on("mediastate", function (state) {});

    newCall.on("info", function (info) {});

    newCall.on("otherdevice", function (data) {
      if (
        (data.type === "CALL_STATE" && data.code >= 200) ||
        data.type === "CALL_END"
      ) {
        // callEnded();
      }
    });
    // if (!modal) {
    //   setTimeout(() => {
    //     joinRoom();
    //   }, 3000); // 3000 milliseconds = 3 seconds
    // }
  };
  function joinRoom() {
    if (clickButton.current) {
      clickButton.current.click();
    }
    setIsOpen(false);
    setConnectionDestroy(true);
    setIsCallActive(true);
    isAttendantBusyRef.current = true;
    call.answer(function (res) {});
    updateCall(call.callId, "accepted", 0);
    intervalIdRef.current = setInterval(updateElapsedTime, 1000);
    backgroundAudio.pause();
  }
  const handleHangup = async () => {
    try {
      let nextCall;
      if (clickButton.current) {
        clickButton.current.click();
      }

      // Hang up the call if there is one
      if (call) {
        call.hangup();
        updateCall(call.callId, "completed", elapsedTime);
        clearInterval(intervalIdRef.current);
        setElapsedTime(0);
        setCall(null);
      }
      isAttendantBusyRef.current = false;
      isAttendantModalRef.current = true;
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = null;
      }
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = null;
      }
      setIsAttendantBusy(false);
      // Update state variables
      setIsOpen(false);
      setLocalStream(null);
      setRemoteStream(null);
      setConnectionDestroy(false);
      setCall(null);

      socket.emit("destroyCallConnection", callerId);
      if (incomingCalls.length > 0) {
        // Dequeue the next call

        // const currentCall = call;

        // Handle the next call
        // setCall(incomingCalls?incomingCalls[0]:'');
        // Handle the call connection

        // await handleIncomingCall(
        //   nextCall && incomingCalls ? incomingCalls[0] : ""
        // );
        // Set call as active
        // setIsCallActive(true);
        // Show call interface
        // setIsOpen(true);
        nextCall = incomingCalls.shift();
        isAttendantModalRef.current = false;
        setCallQueue((prevQueue) =>
          prevQueue.filter((call) => call !== nextCall)
        );
        callCountRef.current = parseInt(callCountRef.current) - 1;
        await handleLogin(accessToken, false);
      } else {
        // No more calls in the queue, reset the call state
        setCallQueue((prevQueue) => prevQueue.filter((data) => call !== call));
        setCallingQueue([]);
      }
    } catch (error) {
      console.error("Error hanging up the call:", error);
      // Handle error if needed
    }
  };
  const newClientInitialize = (token, modal) => {
    const client = new StringeeClient();

    client.on("connect", function () {});

    client.on("authen", function (res) {
      if (res.r === 0) {
        setLoggedUserId(res.userId);
      } else {
        setLoggedUserId(res.message);
      }
    });
    // client.on("incomingcall", function (incomingcall) {
    // Proceed with handling the new call
    client.on("incomingcall", function (incomingcall) {
      handleIncomingCall(incomingcall, modal);
    });
    client.on("disconnect", function () {
      // alert("disconnect");
    });

    // Function to handle incoming calls

    client.on("requestnewtoken", function () {
      //please get new access_token from YourServer and call:
      //client.connect(new_access_token);
    });

    setStringeeClient(client);
    client.connect(token);
  };
  const handleMuteToggle = () => {
    if (call) {
      call.mute(!muted);
      setMuted(!muted);
    }

    if (clickButton.current) {
      clickButton.current.click();
    }
  };
  const handleVideoToggle = () => {
    if (call) {
      const updatedVideoEnabled = !videoEnabled;
      call.enableLocalVideo(updatedVideoEnabled);
      setVideoEnabled(updatedVideoEnabled);
    }
    if (clickButton.current) {
      clickButton.current.click();
    }
  };

  const ring = async () => {
    backgroundAudio.play();
  };
  const clickButtonFun = async () => {
    backgroundAudioClick.play();
  };

  const getCallData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(`${url}/api/call/getAllCallData/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      setMaxDuration(json.maxDurationCall);
      setAvgDuration(json.averageDuration);
      setMissedCalls(json.totalStartCalls);
      callDuration.current = json.averageDuration;
    } catch (error) {
      console.error(error);
    }
  };
  const type = "sales";
  const getCallerlist = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(
        `${url}/api/call/getAllCallerList/${slug}?page=${currentPage}&limit=${itemsPerPage}&type=${type}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      console.log(json, "json data");
      setCallersList(json.callerList);
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCallData();
    setShowIncomingCalls(true);
  }, []);

  useEffect(() => {
    getCallerlist();
  }, [currentPage]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <div className="body-wrap mt-3">
      <div className="container-fluid px-0">
        <div className="general-dashboard">
          <div className="container-fluid">
            <div className=" ">
              {call && (
                <div className="row">
                  <div className="col-12 col-lg-12 order-md-last call-side">
                    <div className="d-flex justify-content-center flex-row flex-wrap gap-2">
                      <div className="d-flex justify-content-center flex-row flex-wrap gap-2 mt-2">
                        <div className="calling-area">
                          {call && (
                            <div className="user-video-screens">
                              <div
                                className=""
                                style={{
                                  background:
                                    "linear-gradient(#D3D3D3, #D3D3D3)",
                                  position: "relative",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <div className="call-time">
                                  <p>{`${Math.floor(elapsedTime / 60)}:${(
                                    elapsedTime % 60
                                  )
                                    .toString()
                                    .padStart(2, "0")}`}</p>
                                </div>
                                <div className="video-wrapper">
                                  <video
                                    ref={localVideoRef}
                                    autoPlay
                                    muted
                                    className="local-stream"
                                  ></video>
                                </div>
                                <video
                                  ref={remoteVideoRef}
                                  autoPlay
                                  style={{ position: "relative" }}
                                  width="100%"
                                  height="100%"
                                  controls={false}
                                ></video>
                              </div>

                              <div className="bottom-bar-call seperat-row mb-2">
                                <div className="icons-wrap-end-call ">
                                  <div
                                    className="icon-call"
                                    onClick={handleScreenshot}
                                  >
                                    <img src={Screenshot} alt="screen shot" />
                                  </div>
                                  <div className="icon-call">
                                    {videoEnabled ? (
                                      <img
                                        src={SwtchVideo}
                                        alt="Unmute Video"
                                        className="mx-2 d-flex justify-content-center"
                                        width={27}
                                        height={27}
                                        onClick={handleVideoToggle}
                                      />
                                    ) : (
                                      <img
                                        src={UnmuteVideo}
                                        alt="Mute Video"
                                        className="mx-2 d-flex justify-content-center  rounded"
                                        width={27}
                                        height={27}
                                        onClick={handleVideoToggle}
                                      />
                                    )}
                                  </div>

                                  <div
                                    className="icon-call dec-call-video"
                                    onClick={() => handleHangup()}
                                  >
                                    <img src={DecVideCall} alt="" />
                                  </div>
                                  <div className="icon-call">
                                    {!muted ? (
                                      <img
                                        src={UnmuteSwitch}
                                        alt="UnMute Call"
                                        className="mx-2 d-flex justify-content-center"
                                        width={27}
                                        height={27}
                                        onClick={handleMuteToggle}
                                      />
                                    ) : (
                                      <img
                                        src={MuteSwitch}
                                        alt="Mute Call"
                                        className="mx-2 d-flex justify-content-center  rounded "
                                        width={27}
                                        height={27}
                                        onClick={handleMuteToggle}
                                      />
                                    )}
                                  </div>
                                  <div className="icon-call">
                                    <img src={FlipCamera} alt="flip camera" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleHangup}
          contentLabel="Schedule Access Modal"
          className="modal-content"
          style={{
            overlay: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
            content: {
              top: "0%",
              // backgroundColor: "white",
              // padding: "20px",
              // margin: "auto",
              width: "30%", // Set a width
              // maxHeight: "100vh", // Limit the height of the modal
              // overflowY: "auto", // Enable vertical scrolling
            },
          }}
          shouldCloseOnOverlayClick={false}
        >
          <div className="p-4  col-12 rounded-3">
            <div>
              <div className="d-flex justify-content-center  gap-2">
                <h2>Video Attendant</h2>
              </div>

              <div className="form-box card-box">
                <div className="call-detail-bar">
                  <div className="call-options-wrap">
                    <div className="icons-wrap-options justify-content-center align-items-center">
                      <div className="icon-dec">
                        <img src={DeclineIcon} alt="" onClick={handleHangup} />
                      </div>

                      <div className="icon-att-vid" onClick={joinRoom}>
                        <img src={AttendVideoIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {/* <a ref={remoteButtonRef} onClick={() => answerCall(slug)}></a> */}
      <a ref={leaveCallButtonRef} onClick={handleHangup}></a>
      <a ref={ringCall} onClick={ring}></a>
      <a ref={clickButton} onClick={clickButtonFun}></a>
      <a ref={clickJoin} onClick={joinRoom}></a>
    </div>
  );
};

export default VideoAttendant;
