import React from "react";
import Logo from "../../images/logo.svg";
import IconDashboard from "../../assets/icons/icon-dashboard.svg";
import { Link, useNavigate } from "react-router-dom";
import IconUser from "../../assets/icons/icon-user.svg";
import IconMedia from "../../assets/icons/icon-media.png";
import IconTenant from "../../assets/icons/icon-tenant.svg";
import { NavLink } from "react-router-dom";
import "../style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/Authentication";
const Sidebar = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleUserLogout = async (e) => {
    await localStorage.removeItem("apiToken");
    await localStorage.removeItem("externalUser");
    dispatch(logout());
    const slug = localStorage.getItem("slug");
    navigate(`/${slug}/va`);
  };
  return (
    <div>
      <section className="sidebar_wrap">
        <div className="logo-wrap text-center">
          <img
            src="/locker_logo.png"
            alt="logo"
            className="logo-image-style img-fluid mx-auto"
          />
        </div>
        <ul>
          <li>
            <Link to="/va/dashboard">
              <img src={IconDashboard} alt="icon" />
              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/va/sites">
              <img src={IconDashboard} alt="icon" />
              <span>Sites</span>
            </Link>
          </li>

          {/* <li>
            <Link to="/netflix">
              <img src={IconMedia} alt="icon" />
              <span>NetFlix</span>
            </Link>
          </li> */}
          <li>
            <Link to="/va/users">
              <img src={IconTenant} alt="icon" />
              <span>Users</span>
            </Link>
          </li>
          <li>
            <Link to="/va/email/configration">
              <img src={IconDashboard} alt="icon" />
              <span>Email Configration</span>
            </Link>
          </li>
          <li>
            <Link to="/va/locker/history">
              <img src={IconDashboard} alt="icon" />
              <span>Locker History</span>
            </Link>
          </li>
          <li>
            <Link to="/va/api/access">
              <img src={IconDashboard} alt="icon" />
              <span>API Access</span>
            </Link>
          </li>
          <li>
            <Link onClick={handleUserLogout}>
              <img src={IconUser} alt="icon" />
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </section>
    </div>
  );
};
export default Sidebar;
