import React, { useEffect, useState } from "react";
import Header2 from "../../../partials/Header2";
import Sidebar from "../../../partials/SidebarExternal";
import BackIcon from "../../../../images/icon-back.png";
// import UploadIcon from '../../../images/icon-upload.png'
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
const List = () => {
  const isAuthenticated = useSelector((state) => state.user);

  const apiUrl = process.env.REACT_APP_API_URL;
  const pin = isAuthenticated?.pin;
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",

    pin: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [apiToken, setApiToken] = useState(
    localStorage.getItem("apiToken") || ""
  );
  const apiLogin = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${apiUrl}/api/auth/login/pin`, {
        pin,
      });
      const data = await response.data;
      setIsLoading(false);
      if (data?.success) {
        localStorage.setItem("apiToken", data?.token);
        setApiToken(data?.token);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiToken);
    setCopied(true);

    // Reset "Copied" text after 2 seconds
    setTimeout(() => setCopied(false), 2000);
  };
  const [isActive, setIsActive] = useState(false);
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <div className="general-dashboard mt-5">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <div className="user-back-det">
                              <h3>API Access</h3>
                              <p>
                                List of all the API access registered in the
                                system
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}

                    <div className="form-box card-box">
                      <div className="row">
                        <div className="col-lg-12">
                          {/* input row */}
                          <div className="row">
                            <div className="col-xl-3 col-lg-3">
                              <div className="label-wrap">
                                <h4>Api Endpoint</h4>
                              </div>
                            </div>
                            <div className="col-xl-9 col-lg-9">
                              <div className="inpt-wrap">
                                <div className="inpt-wrap">
                                  <h3>https://api.invidtech.com</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* input row */}
                        </div>
                        <div className="col-lg-12">
                          {/* input row */}

                          <div className="row">
                            <div className="col-xl-3 col-lg-3">
                              <div className="label-wrap">
                                <h4>Api Token</h4>
                                <p>Enter the api token of the user</p>
                              </div>
                            </div>
                            <div className="col-xl-9 col-lg-9">
                              <div className="inpt-wrap">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="sitec"
                                  placeholder="User API Key"
                                  name="apiKey"
                                  value={apiToken}
                                  disabled
                                />
                                <button
                                  className="btn btn-primary ms-2 mt-2"
                                  onClick={copyToClipboard}
                                  disabled={!apiToken}
                                >
                                  {copied ? "Copied!" : "Copy"}
                                </button>
                                <button
                                  className="btn btn-primary ms-2 mt-2"
                                  onClick={apiLogin}
                                  disabled={!apiToken}
                                >
                                  {isLoading ? "Loading..." : "Refresh"}
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* input row */}
                        </div>

                        <div className="col-lg-12 ">
                          <h6>
                            Please review the API documentation
                             <a href="https://api.invidtech.com" target="_blank">
                               API Docs Link
                            </a>
                            . Use PIN: 123456 to access the docs. Let us know if
                            you have any questions!
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default List;
