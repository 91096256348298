import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Sidebar from "../../Components/partials/Sidebar";
import Header from "../../Components/partials/Header2";
const url = process.env.REACT_APP_APP_BACK_URL;

const List = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [temparature, settemparature] = useState("66.0");
  const [usersRequestsNotFound, setUsersRequestsNotFound] = useState(false);
  const [credentials, setCredentials] = useState({
    name: "",
  });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const role = isAuthenticated.role;
  const site = isAuthenticated.site;
  const id = isAuthenticated._id;
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  const tableData = [
    {
      id: 1,
      name: "Imran has requested to access the Invid site",
      // site: "Invid",
      pin: 1234,
      date: "Mar 28, 2024 01:04pm",
      status: "Closed",
    },
    {
      id: 2,
      name: "Hamza has requested to access the locker# 4",
      // locker: "Locker 4",
      pin: 1234,
      date: "Mar 28, 2024 01:04pm",
      status: "Opened",
    },
    {
      id: 3,
      name: "Shoaib has requested to access the Invid site",
      // site: "Invid",
      pin: 1234,
      date: "Mar 28, 2024 01:04pm",
      status: "Opened",
    },
  ];

  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const iocnStyle1 = {
    display: isLoading1 ? "inline-block" : "none",
  };
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  };

  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header isActive={isActive} toggleActive={toggleActive} />

                {/* {isAuthenticated.role.toLowerCase() === "site user" && (
        <> */}

                <div className="container-wrap m-3">
                  <div className="container-text">
                    <h5>Access Request</h5>

                    <div className="dashboard-table-view">
                      <div className={styles.tables_wrap}>
                        <div className="table-responsive">
                          <table
                            cellPadding="10px"
                            className="table align-middle dashboard-table"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th>No.</th>
                                <th>Requested By</th>
                                <th>Datetime</th>
                                <th>Status</th>
                                <th>PIN</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.length > 0 &&
                                tableData.map((rowData, key) => (
                                  <tr
                                    key={rowData._id}
                                    className="row-data"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <td></td>
                                    <td>{key + 1}</td>
                                    <td className="text-capitalize">
                                      {rowData.name}
                                    </td>
                                    <td>{rowData.date}</td>

                                    <td>
                                      <span
                                        className={
                                          rowData.status === "Closed"
                                            ? "status danger"
                                            : "status success"
                                        }
                                      >
                                        {rowData.status}
                                      </span>
                                    </td>
                                    <td className="text-capitalize">
                                      {rowData.pin}
                                    </td>
                                    <td className="text-center">
                                      <button className="btn btn-prim w-auto mb-2">
                                        <i
                                          className="fa fa-spinner fa-spin"
                                          style={iocnStyle}
                                        ></i>{" "}
                                        Assign
                                      </button>
                                    </td>
                                  </tr>
                                ))}

                              {usersRequestsNotFound && (
                                <tr>
                                  <td colSpan={6} className="text-center">
                                    <p className="text-center text-danger">
                                      No data found.
                                    </p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container-wrap">
                  <div className="container-text mb-5">
                    <h5 className="fw-bold ">Users Statistics</h5>
                  </div>

                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      {/* Locker 1 */}
                      {tableData.map((item, key) => (
                        <div className="col-md-4">
                          <div className="card shadow-sm p-3 mb-3">
                            <div className="card-body text-center">
                              <h5 className="card-title">User {key + 1}</h5>
                              <p className="text-muted">PIN: 1234</p>
                              <p>
                                🔄 Requested: <strong>12 times</strong>
                              </p>
                              <p>
                                🔓 Status: <strong>Opened</strong>
                              </p>
                              <p>
                                📅 Monthly Usage: <strong>12 times</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}

                      {/* Repeat for more lockers */}
                    </div>
                  </div>
                </div>
                <div className="container-wrap">
                  <div className="container-text mb-5">
                    <h5 className="fw-bold ">Temperature</h5>
                  </div>

                  <div className="container-fluid">
                    <div className="row ">
                      {/* Locker 1 */}

                      <div className="col-md-4">
                        <div className="card shadow-sm p-3 mb-3 justify-content-center">
                          <div className="card-body ">
                            <h3 className="fw-700 bg-white p-2 rounded shadow-sm">
                              {temparature}°F
                            </h3>
                          </div>
                        </div>
                      </div>

                      {/* Repeat for more lockers */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default List;
