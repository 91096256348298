import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import BackIcon from "../../../../images/icon-back.png";

const EditData = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const react_base_url = process.env.REACT_APP_BASE_URL;
  const isAuthenticated = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [error, setError] = useState(null);

  const [credentials, setCredentials] = useState({
    host: "",
    port: "",
    user: "",
    password: "",
    status: "",
  });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    (async () => {
      getData();
    })();
  }, []);

  const getData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      // setIsLoading(true);
      const response = await fetch(
        `${url}/api/pickup/getById_communication/${id}`,
        {
          mode: "cors",
          method: "get",
          headers: headers,
        }
      );
      // setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        let usr = json.data;
        setCredentials({
          host: usr.host,
          port: usr.port,
          user: usr.user,
          password: usr.password,
          status: usr.status,
        });
      } else {
        toast.error(json?.error);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      const response = await fetch(
        `${url}/api/pickup/update_communication/${id}`,
        {
          mode: "cors",
          method: "PUT",
          headers: headers,
          body: JSON.stringify({
            port: credentials.port,
            host: credentials.host,
            user: credentials.user,
            password: credentials.password,
            status: credentials.status,
          }),
        }
      );
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        toast.success("Updated Successfully");
      } else {
        toast.error(json?.error);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };

  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/communications/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Update Communication Configuration</h3>
                  <p>
                    Enter the credentials to update configuration to the system
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Host</h4>
                      <p>Enter the host of communication</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="host"
                        placeholder="Host"
                        name="host"
                        value={credentials.host}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Port</h4>
                      <p>Enter port of the communication</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="number"
                        className="form-control"
                        id="port"
                        placeholder="Port"
                        name="port"
                        value={credentials.port}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>

              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>User</h4>
                      <p>Enter the User of Authentication</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="user"
                        placeholder="User"
                        name="user"
                        value={credentials.user}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Password</h4>
                      <p>Enter password for Authentication</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="password"
                        placeholder="Password"
                        name="password"
                        value={credentials.password}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Status</h4>
                      <p>Select status</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="status"
                        value={credentials.status}
                        onChange={handleInput}
                        required
                        disabled
                      >
                        <option value="">Choose Status</option>
                        <option key={1} value={"active"}>
                          Active
                        </option>
                        <option key={2} value={"deactive"}>
                          Deactive
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>

              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button
                    type="submit"
                    disabled={isLoading}
                    href=""
                    className="btn btn-prim w-auto ms-5"
                  >
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default EditData;
