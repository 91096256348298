import React, { useEffect, useState, useRef } from "react";
import "../../simplelocker.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formattedDateTime1, formattedDateTime } from "../../utils/generic";
// import { io } from "socket.io-client";

const imageUrl = process.env.REACT_APP_APP_IMAGE_URL;
const url = process.env.REACT_APP_APP_BACK_URL;
const socket_url = process.env.REACT_APP_APP_SOCKET_URL;
// const socket = io.connect(socket_url);
let idleTimer;

const CallHeader = () => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  let { slug } = useParams();
  const [site, setSite] = useState({});
  const [siteLogo, setSiteLogo] = useState("");

  useEffect(() => {
    siteData();
  }, []);
  useEffect(() => {
    // Update time every second
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getSite/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSite(json.site);

        setSiteLogo(imageUrl + json.site.image);
      } else {
        toast.error("No site found! Enter valid site");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <header className="header">
        <div className="row mb-2">
          <div className="prof-sett">
            <div className="col-4 d-flex justify-content-center ">
              <div className="main-heading">
                <div className="locker-logo">
                  <div className="locker-style-image d-flex justify-content-center ">
                    {siteLogo && (
                      <img
                        className="logo-image-style img-fluid mx-auto"
                        src={siteLogo}
                        alt="Logo"
                        onError={(e) => {
                          e.target.src = "/locker_logo.png";
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-center px-3"></div>

            <div className="col-4 d-flex justify-content-center px-3">
              <div className="locker-timer position-relative">
                <p>{formattedDateTime(currentDateTime)}</p>
                <p>{formattedDateTime1(currentDateTime)}</p>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default CallHeader;
