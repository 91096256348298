import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import styles from "./style.module.css";
import BackIcon from "../../images/icon-back.png";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const VAProperty = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const { slug } = useParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [barcodeScan, setBarcodeScan] = useState(false);
  const [finishBarcodeScan, setFinishBarcodeScan] = useState(false);
  const [collecting, setCollecting] = useState(false);
  const [locker, setLocker] = useState("");
  const [site, setSite] = useState("");
  const [distributing, setDistributing] = useState(false);
  const [barcode, setBarcode] = useState("");
  const collect = () => {
    setBarcode("");
    setCollecting(true);
    setBarcodeScan(true);
    setDistributing(false);
  };
  const distribute = () => {
    setBarcode("");
    setDistributing(true);
    setCollecting(false);
    setBarcodeScan(false);
  };
  // open locker
  const openLocker = async () => {
    try {
      let state = "2";
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      const response = await fetch(`${url}/api/sites/relay/state/update`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id: id,
          state: state,
        }),
      });

      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        toast.success(json.message);
        setIsLockerOpen(true);
        setLocker(json?.locker);
        setSite(json?.site);
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      console.log(error);
    }
  };
  useEffect(() => {
    let timeout;
    const handleKeyDown = (evt) => {
      if (timeout) clearTimeout(timeout);

      if (evt.code === "Enter") {
        if (barcode) {
          setFinishBarcodeScan(true);
        }
        return;
      }

      if (evt.key !== "Shift" && evt.key !== "Enter") {
        setBarcode((prev) => prev + evt.key);
      }

      timeout = setTimeout(() => setBarcode(""), 500); // Reset barcode after 500ms
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      if (timeout) clearTimeout(timeout);
    };
  }, [barcode]);
  const lockerHistory = async (type) => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("docgouser_token");
      const response = await fetch(`${apiUrl}/api/history`, {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        body: JSON.stringify({
          locker_id: id,
          locker_number: locker,
          site: site,
          type: type,
          state: "2",
          reason: `${type} property ${barcode} from ${locker}`,
        }),
      });
      setIsLoading(false);
      const json = await response.json();
      if (json?.status) {
        setCollecting(false);
        setBarcodeScan(false);
        setDistributing(false);
        setIsLockerOpen(false);
        setFinishBarcodeScan(false);
        setBarcode("");
        setLocker("");
        setSite("");
        toast.success("History Created Successfully");
      } else {
        setIsLoading(false);
        toast.error(json.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-1 my-auto"></div>
            <div className="col-lg-11 my-auto ">
            {isAuthenticated && (
              <div className="user-wrap user-back-wrap gap-3">
                <div className="back-icon">
                  <img src={BackIcon} alt="" onClick={() => navigate(-1)} />
                </div>

                <div className="user-back-det">
                  <h3>Back</h3>
                </div>
              </div>
                      )}
            </div>
          </div>

          <div className="d-flex vh-90 justify-content-center align-items-center bg-light">
            <div
              className="rounded shadow-lg bg-white p-4 text-center w-100"
              style={{ maxWidth: "500px" }}
            >
              {/* Top Icon */}
              <div className="p-3 border rounded bg-light d-inline-block">
                <img
                  src="/home.png"
                  alt="Property Icon"
                  className="img-fluid"
                  style={{ width: "40px", height: "40px" }}
                />
              </div>

              {/* Title & Description */}
              <h1 className="fs-5 fw-bold mt-3">Property</h1>
              <p className="text-secondary small mt-2 mb-4">
                Select the action that you want to perform
              </p>

              {/* Buttons Section - Now in the Same Row */}
              <div className="d-flex flex-row justify-content-between gap-3 m-3">
                {/* Collect Button */}
                {!collecting && !distributing && (
                  <>
                    <div
                      className="border rounded shadow-sm text-center py-3 w-50 d-flex flex-column align-items-center justify-content-center bg-white"
                      onClick={() => collect()}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src="/collecting.png"
                        alt="Collect Icon"
                        className="img-fluid mb-2"
                        style={{ width: "50px", height: "50px" }}
                      />
                      <h2 className="fs-6 fw-semibold">COLLECT</h2>
                    </div>

                    <div
                      className="border rounded shadow-sm text-center py-3 w-50 d-flex flex-column align-items-center justify-content-center bg-white"
                      onClick={() => distribute()}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src="/distribute.png"
                        alt="Distribute Icon"
                        className="img-fluid mb-2"
                        style={{ width: "50px", height: "50px" }}
                      />
                      <h2 className="fs-6 fw-semibold">DISTRIBUTE</h2>
                    </div>
                  </>
                )}
              </div>
              {collecting && (
                <>
                  {barcodeScan && (
                    <>
                      <h5 className="text-lg font-medium text-gray-900 mt-2">
                        Scan the Barcode from the property’s
                      </h5>
                      <h6 className="text-lg font-semibold text-gray-900 mt-2">
                        {barcode ? barcode : ""}
                      </h6>
                    </>
                  )}
                  {!isLockerOpen && finishBarcodeScan && (
                    <button
                      className="btn btn-prim w-auto ms-5"
                      onClick={() => openLocker()}
                      disabled={isLoading}
                    >
                      <h2 className="fs-6 fw-semibold">
                        Open Locker
                        {isLoading && <i className="fa fa-spinner fa-spin"></i>}
                      </h2>
                    </button>
                  )}
                  {isLockerOpen && finishBarcodeScan && (
                    <button
                      className="btn btn-prim w-auto ms-5"
                      onClick={() => lockerHistory("Collecting")}
                      disabled={isLoading}
                    >
                      <h2 className="fs-6 fw-semibold">
                        Finish Collecting
                        {isLoading && <i className="fa fa-spinner fa-spin"></i>}
                      </h2>
                    </button>
                  )}
                </>
              )}
              {distributing && (
                <>
                  <div className="form-btn-wrap">
                    {!isLockerOpen && (
                      <button
                        className="btn btn-prim w-auto"
                        onClick={() => openLocker()}
                        disabled={isLoading}
                      >
                        Open Locker
                        {isLoading && <i className="fa fa-spinner fa-spin"></i>}
                      </button>
                    )}
                  </div>
                  {isLockerOpen && (
                    <>
                      <h5 className="text-lg font-medium text-gray-900 mt-2">
                        Scan the Barcode from the property’s
                      </h5>
                      <h6 className="text-lg font-semibold text-gray-900 mt-2">
                        {barcode ? barcode : ""}
                      </h6>
                    </>
                  )}
                  {finishBarcodeScan && (
                    <button
                      className="btn btn-prim w-auto ms-5"
                      onClick={() => lockerHistory("Distributing")}
                      disabled={isLoading}
                    >
                      <h2 className="fs-6 fw-semibold">
                        Finish Distributing
                        {isLoading && <i className="fa fa-spinner fa-spin"></i>}
                      </h2>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VAProperty;
