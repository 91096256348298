import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import SearchIcon from "../../../assets/icons/search.svg";
import Sidebar from "../../../Components/partials/Sidebar";
import Header from "../../../Components/partials/Header2";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import {
  checkFileExists,
  formattedDateTime1,
  formattedDateTime,
} from "../../../utils/generic";
const List = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isImageLarge, setIsImageLarge] = useState(false);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [credentials, setCredentials] = useState({ status: "all", name: "" });
  const handleInput = (e) => {
    setCurrentPage(1);
    setCredentials((prevCredentials) => {
      const updatedCredentials = {
        ...prevCredentials,
        [e.target.name]: e.target.value,
      };
      fetchData(updatedCredentials);
      return updatedCredentials;
    });
  };
  useEffect(() => {
    fetchData(credentials);
  }, [currentPage]);
  const fetchData = async (fetchCredentials) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const authToken = localStorage.getItem("token");
      headers.append("auth-token", authToken);
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(
        `${url}/api/external/list?page=${currentPage}&limit=${itemsPerPage}&name=${fetchCredentials.name}&status=${fetchCredentials.status}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.externalUsers.length > 0) {
        setIsDisplay(true);
        setData(json.externalUsers);
      } else {
        setIsNotFound(true);
      }
      setTotalPages(json.totalPages);
    } catch (error) {
      setIsLoading(false);
      setIsDisplay(false);
      setIsNotFound(true);
      console.error(error);
    }
  };
  const handleResetBtn = () => {
    const updatedCredentials = {
      name: "",
      status: "all",
    };
    // Update state and then call fetchData
    setCredentials(updatedCredentials);
    setCurrentPage(1);
    // Call fetchData with the updated credentials
    fetchData(updatedCredentials);
  };
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const authToken = localStorage.getItem("token");
        headers.append("auth-token", authToken);
        const response = await fetch(`${url}/api/external/delete/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        });
        if (response.ok) {
          fetchData(credentials); // Refresh the records list after successful deletion
          Swal.fire(
            "Deleted!",
            "External user deleted successfully!",
            "success"
          );
        } else {
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
      Swal.fire(
        "Error",
        "An error occurred while deleting the record.",
        "error"
      );
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  function toggleFilterSection() {
    setIsFilterVisible(!isFilterVisible);
  }
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const changeStatus = async (id, status) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You wont change the status of the user to " + status + " !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, change it!",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const authToken = localStorage.getItem("token");
        headers.append("auth-token", authToken);
        const response = await fetch(
          `${url}/api/external/update/status/${id}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
            body: JSON.stringify({ status }),
          }
        );
        const json = await response.json();
        if (json.success) {
          fetchData(credentials); // Refresh the records list after successful deletion
          Swal.fire("Updated!", json.message, "success");
        } else {
          Swal.fire("Error", json.error, "error");
        }
      }
    } catch (error) {
      console.log("Error changing status:", error);
    }
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header isActive={isActive} toggleActive={toggleActive} />
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap">
                            <h3>External Users</h3>
                            <p>
                              List of all the external users registered in the
                              system
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end">
                          <div className="filter-right-sec">
                            <div className="filter-wrap">
                              <div
                                className="filter-box"
                                onClick={toggleFilterSection}
                              >
                                <i className="fas fa-filter" />
                              </div>
                            </div>
                            <Link
                              to="/user/pin/add"
                              className="btn btn-prim w-auto"
                            >
                              Add New External User
                            </Link>
                          </div>
                        </div>
                      </div>
                      {isFilterVisible && (
                        <>
                          <motion.div
                            className={`row mt-5 filter-section`}
                            initial={{ opacity: 0 }} // Initial state (hidden)
                            animate={{ opacity: isFilterVisible ? 1 : 0 }} // Animate opacity based on isFilterVisible
                            transition={{ duration: 1.2, delay: 0.1 }}
                          >
                            <div className="col-12">
                              <div className="filter-right-sec filter-wrap-chat">
                                <div className="search-wrap">
                                  <input
                                    type="search"
                                    placeholder="search by username"
                                    name="name"
                                    value={credentials.name}
                                    onChange={handleInput}
                                  />
                                  <div className="search-box">
                                    <img src={SearchIcon} alt="" />
                                  </div>
                                </div>
                                <div className="filter-select d-flex align-items-center">
                                  <label htmlFor="">Status</label>
                                  <select
                                    name="status"
                                    id=""
                                    value={credentials.status}
                                    onChange={handleInput}
                                  >
                                    <option value="all">Choose Status</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                  </select>
                                </div>
                                <Link
                                  to=""
                                  onClick={handleResetBtn}
                                  className="btn btn-prim w-auto"
                                >
                                  RESET
                                </Link>
                              </div>
                            </div>
                          </motion.div>
                        </>
                      )}
                    </div>
                    {/* row */}
                    <div className="table-wrap">
                      <div className="row">
                        <div className="col-12">
                          <div className={styles.card_box}>
                            <div className={styles.tables_wrap}>
                              <div className="table-responsive">
                                <table className="table align-middle">
                                  <thead>
                                    <tr>
                                      <th>email</th>
                                      <th>pin</th>
                                      <th>events</th>
                                      <th>status</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isLoading && (
                                      <>
                                        <tr>
                                          <td
                                            colSpan={5}
                                            className="text-center"
                                            style={{
                                              background: "transparent",
                                            }}
                                          >
                                            <HashLoader
                                              color={`#0b0d41`}
                                              loading={isLoading}
                                              size={40}
                                              aria-label="Loading Spinner"
                                              data-testid="loader"
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                    {isDisplay &&
                                      data.map((item, i) => (
                                        <motion.tr
                                          key={item._id}
                                          initial={{ opacity: 0 }}
                                          animate={{ opacity: 1 }}
                                          transition={{
                                            duration: 1.2,
                                            delay: i * 0.1,
                                          }}
                                        >
                                          <td>{item?.email}</td>
                                          <td>{item?.pin}</td>
                                          <td>
                                            {item?.events.map((event) => (
                                              <div
                                                key={event._id}
                                                className="d-flex"
                                              >
                                                {event.title}{" "}
                                                {formattedDateTime(event.start)}{" "}
                                                {formattedDateTime1(event.end)}
                                              </div>
                                            ))}
                                          </td>
                                          <td>
                                            {item?.status === "active" ? (
                                              <span
                                                className="status success"
                                                onClick={() =>
                                                  changeStatus(
                                                    item._id,
                                                    "inactive"
                                                  )
                                                }
                                              >
                                                {item?.status}
                                              </span>
                                            ) : (
                                              <span
                                                className="status danger"
                                                onClick={() =>
                                                  changeStatus(
                                                    item._id,
                                                    "active"
                                                  )
                                                }
                                              >
                                                {item?.status}
                                              </span>
                                            )}
                                          </td>
                                          <td className="d-flex gap-2 flex-wrap">
                                            <Link
                                              to={`/user/pin/edit/${item._id}`}
                                            >
                                              <button
                                                type="button"
                                                className="btn btn-edit tb-btn"
                                              >
                                                Edit
                                              </button>
                                            </Link>

                                            <button
                                              type="button"
                                              className="btn btn-delete tb-btn"
                                              onClick={() =>
                                                handleDelete(item._id)
                                              }
                                            >
                                              Remove
                                            </button>
                                          </td>
                                        </motion.tr>
                                      ))}
                                    {isNotFound && (
                                      <tr>
                                        <td colSpan={6} className="text-center">
                                          No Data Found
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <div className="table-num">
                                {data.length > 0 && (
                                  <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    pageCount={totalPages}
                                    forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                                    onPageChange={handlePageChange}
                                    containerClassName={" dig-num"}
                                    pageClassName={"dig-num"}
                                    previousClassName={"num-btns"}
                                    nextClassName={"num-btns"}
                                    disabledClassName={"pagination-disabled"}
                                    activeClassName={"pagination-active"}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default List;
