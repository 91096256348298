import React, { useState } from "react";
import SmallLockerClosed from "../../assets/lockers/small_locker_closed.png";
import SmallLockerOpen from "../../assets/lockers/small_locker_open.png";
import Tablet from "../../assets/lockers/tablet.png";
import style from "./style.module.css";

const TableGrid = ({ lockers, openLocker, success, slug, userLogin }) => {
  const checkStatusOpen = (number) => {
    return number === success;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <table
        className={style.lockersTable}
        style={{
          tableLayout: "fixed",
          margin: "auto",
          textAlign: "center",
          borderCollapse: "collapse",
          width: "fit-content",
        }}
      >
        <tr>
          <td
            style={{
              border: "none",
              padding: "10px",
              border: "2px solid #000",
              width: "fit-content",
              height: "fit-content",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {/* Top Locker (Opened & Rotated) */}
            <div
              id="locker-7"
              className={style.lockerContainer}
              style={{ position: "relative" }}
            >
              <div
                className={style.lockerNum}
                style={{ position: "absolute", top: "30px", left: "130px" }}
              >
                1
              </div>
              <img
                className={style.lockerImg}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  cursor: "pointer",
                  minHeight: "25vh",
                }}
                onClick={() => {
                  openLocker(`${lockers[0]?._id}`);
                }}
                src={
                  checkStatusOpen(`${lockers[0]?._id}`)
                    ? SmallLockerOpen
                    : SmallLockerClosed
                }
                alt="Locker 1"
              />
            </div>

            {/* Tablet Placeholder (Centered) */}
            <div
              id="locker-8"
              className={style.lockerContainer}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "10px 0",
              }}
            >
              <img
                className={style.lockerImg}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  minHeight: "25vh",
                  cursor: "pointer",
                }}
                src={Tablet}
                alt="Tablet"
              />
            </div>

            {/* Bottom Locker (Closed, Numbered) */}
            <div
              id="locker-8"
              className={style.lockerContainer}
              style={{ position: "relative" }}
            >
              <div
                className={style.lockerNum}
                style={{ position: "absolute", top: "30px", left: "130px" }}
              >
                2
              </div>
              <img
                className={style.lockerImg}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  cursor: "pointer",
                  minHeight: "25vh",
                }}
                onClick={() => {
                  openLocker(`${lockers[1]?._id}`);
                }}
                src={
                  checkStatusOpen(`${lockers[1]?._id}`)
                    ? SmallLockerOpen
                    : SmallLockerClosed
                }
                alt="Locker 2"
              />
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default TableGrid;
