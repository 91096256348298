import React, { useEffect, useState } from "react";
import Header2 from "../../../partials/Header2";
import Sidebar from "../../../partials/SidebarExternal";
import BackIcon from "../../../../images/icon-back.png";
// import UploadIcon from '../../../images/icon-upload.png'
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const ExternalUserEdit = () => {
  const { id } = useParams();
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const apiUrl = process.env.REACT_APP_API_URL;

  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    pin: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleInputSlug = (e) => {
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;

    // Check if the entered value matches the pattern
    if (alphanumericRegex.test(e.target.value) || e.target.value === "") {
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
    }
  };
  const handleInputPin = (e) => {
    let { name, value } = e.target;

    // Remove non-numeric characters and limit to 6 digits
    value = value.replace(/\D/g, "").slice(0, 6);

    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const callApi = async () => {
    try {
      const token = localStorage.getItem("apiToken");
      const response = await fetch(`${apiUrl}/api/users/${id}`, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setCredentials(data.user);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      setIsLoading(true);
      const token = localStorage.getItem("apiToken");
      const response = await fetch(`${apiUrl}/api/users/${id}`, {
        mode: "cors",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        body: JSON.stringify({
          name: credentials.name,
          email: credentials.email,
          password: credentials.password,
          phone: credentials.phone,
          pin: credentials.pin,
        }),
      });

      setIsLoading(false);
      const json = await response.json();
      if (json.success) {

        toast.success("User Updated Successfully");
      } else {
        setIsLoading(false);
        toast.error(json.error);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const [permissions, setPermissions] = useState([]);

  const handlePermissionChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setPermissions([...permissions, value]);
    } else {
      setPermissions(permissions.filter((p) => p !== value));
    }
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <div className="general-dashboard mt-5">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link to="/va/users">
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div className="user-back-det">
                              <h3>Edit User</h3>
                              <p>Edit the user details</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>User Name</h4>
                                  <p>Enter the name of the user</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="sitec"
                                    placeholder="User Name"
                                    name="name"
                                    value={credentials?.name}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>User Email</h4>
                                  <p>Enter the email of the user</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="sitec"
                                    placeholder="User Email"
                                    name="email"
                                    value={credentials?.email}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>User Phone</h4>
                                  <p>Enter the phone of the user</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="number"
                                    placeholder="Enter Phone"
                                    name="phone"
                                    required
                                    value={credentials?.phone}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>

                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>User PIN</h4>
                                  <p>Enter the PIN of the user</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    placeholder="5000"
                                    name="pin"
                                    required
                                    value={credentials?.pin}
                                    onChange={handleInputPin}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>

                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">
                              <a
                                href=""
                                className="btn btn-prim btn-transparent w-auto"
                              >
                                Reset Form
                              </a>
                              <button
                                type="submit"
                                className="btn btn-prim w-auto ms-5"
                                disabled={isLoading}
                              >
                                {isLoading ? "Updating..." : "Update User"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default ExternalUserEdit;
