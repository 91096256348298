import React, { useEffect, useState } from "react";
import Header from "../../../partials/Header.js";
import Sidebar from "../../../partials/SidebarExternal.js";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { Link, useParams, useNavigate } from "react-router-dom";
import BackIcon from "../../../../images/icon-back.png";
import { toast } from "react-toastify";
const List = () => {
  let navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("apiToken");
  let { slug } = useParams();

  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({});
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [credentials, setCredentials] = useState({ type: "all", name: "" });
  const handleInput = (e) => {
    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials((prevCredentials) => {
      const updatedCredentials = {
        ...prevCredentials,
        [e.target.name]: e.target.value,
      };
      fetchData(updatedCredentials);
      return updatedCredentials;
    });
  };
  useEffect(() => {
    fetchData(credentials);
  }, [currentPage]);

  const fetchData = async (fetchCredentials) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${apiUrl}/api/history/?page=${currentPage}&limit=${itemsPerPage}`,
        {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json?.locker_history?.length > 0) {
        setData(json?.locker_history);
        setIsDisplay(true);
      } else {
        setIsNotFound(true);
        setIsDisplay(false);
        setData([]);
        toast.error(json?.message);
      }
      setTotalPages(json?.totalPages);
    } catch (error) {
      setIsNotFound(true);
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };

  const formattedDate = (date) => {
    if (!date) {
      // If date is empty or null, use the current date
      date = new Date();
    }

    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const [isActive, setIsActive] = useState(false);

  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";

  const handleResetBtn = () => {
    const updatedCredentials = {
      name: "",
      type: "all",
    };

    // Update state and then call fetchData
    setCredentials(updatedCredentials);
    setCurrentPage(1);

    // Call fetchData with the updated credentials
    fetchData(updatedCredentials);
  };

  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard mt-5">
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link onClick={() => navigate(-1)}>
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div className="user-wrap">
                              <h3>Locker History</h3>
                              <p>
                                List of all the locker history in the system
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* row */}
                    <div className="table-wrap">
                      <div className="row">
                        <div className="col-12">
                          <div className={styles.card_box}>
                            <div className={styles.tables_wrap}>
                              <div className="table-responsive">
                                <table className="table align-middle">
                                  <thead>
                                    <tr>
                                      <th>User</th>
                                      <th>Locker no</th>
                                      <th>Site</th>
                                      <th>Type</th>
                                      <th>Reason</th>
                                      <th>Date</th>
                                      {/* <th></th> */}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {isLoading && (
                                      <>
                                        <tr>
                                          <td
                                            colSpan={4}
                                            className="text-center"
                                            style={{
                                              background: "transparent",
                                            }}
                                          >
                                            <HashLoader
                                              color={`#0b0d41`}
                                              loading={isLoading}
                                              size={40}
                                              aria-label="Loading Spinner"
                                              data-testid="loader"
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                    {isDisplay && data.length > 0
                                      ? data.map((item, i) => (
                                          <motion.tr
                                            key={item._id}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{
                                              duration: 1.2,
                                              delay: i * 0.1,
                                            }}
                                          >
                                            <td>
                                              <div className="usr_det_tb">
                                                <span>
                                                  {item.user_id?.name}
                                                </span>
                                              </div>{" "}
                                            </td>
                                            <td>
                                              <div className="usr_det_tb">
                                                <span>
                                                  {item.locker_number}
                                                </span>
                                              </div>{" "}
                                            </td>
                                            <td>{item.site}</td>
                                            <td>{item.type}</td>
                                            <td>{item.reason}</td>
                                            <td>
                                              {formattedDate(item.created_at)}
                                            </td>

                                            {/* <td className="text-end">
                                              <button
                                                type="button"
                                                className="btn btn-delete tb-btn"
                                                onClick={() =>
                                                  handleDelete(item._id)
                                                }
                                              >
                                                Remove
                                              </button>
                                            </td> */}
                                          </motion.tr>
                                        ))
                                      : isNotFound && (
                                          <tr>
                                            <td
                                              colSpan={7}
                                              className="text-center"
                                            >
                                              <p className="text-center text-danger">
                                                No data found.
                                              </p>
                                            </td>
                                          </tr>
                                        )}
                                  </tbody>
                                </table>
                              </div>
                              <div className="table-num">
                                {data.length > 0 && (
                                  <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    pageCount={totalPages}
                                    forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                                    onPageChange={handlePageChange}
                                    containerClassName={" dig-num"}
                                    pageClassName={"dig-num"}
                                    previousClassName={"num-btns"}
                                    nextClassName={"num-btns"}
                                    disabledClassName={"pagination-disabled"}
                                    activeClassName={"pagination-active"}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default List;
