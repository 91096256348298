import React, { useEffect, useState } from "react";

import Header from "../../../partials/Header.js";
import Sidebar from "../../../partials/SidebarExternal";
import BackIcon from "../../../../images/icon-back.png";
import styles from "./style.module.css";

import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Add = () => {
  let navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user);
  const apiUrl = process.env.REACT_APP_API_URL;
  let { slug } = useParams();
  const site = isAuthenticated.site;
  const role = isAuthenticated.role;
  const url = process.env.REACT_APP_APP_BACK_URL;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [getSites, setSite] = useState([]);
  const [getLockerS, setLockerSize] = useState([]);
  const [getLockerB, setLockerBank] = useState([]);
  const [suplyLocker, setsuplyLocker] = useState(false);

  useEffect(() => {
    getSite();
    getLockerSize();
    getLockerBank();
  }, []);
  const [credentials, setCredentials] = useState({
    number: "",
    relay: "",
    port: "",
  });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const token = localStorage.getItem("apiToken");
      const response = await fetch(`${apiUrl}/api/lockers/${slug}`, {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        body: JSON.stringify({
          number: credentials.number,
          port: credentials.port,
          relay: credentials.relay,
        }),
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.status) {
        setCredentials({
          number: "",
          port: "",
          relay: "",
        });
        toast.success("Lockers Created Successfully");
      } else {
        toast.error(json?.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };
  const getSite = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getSite/${site}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setSite(json.site);

      // setCredentials({ ...json.site });
    } catch (error) {
      console.error(error);
    }
  };
  const getLockerSize = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/locker/getLockerSize`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();

      setLockerSize(json.locker_size);

      // setCredentials({ ...json.site });
    } catch (error) {
      console.error(error);
    }
  };
  const getLockerBank = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/locker/getLockerBank/${site}?role=${role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      console.log(json.locker_bank);
      setLockerBank(json.locker_bank);

      // setCredentials({ ...json.site });
    } catch (error) {
      console.error(error);
    }
  };
  const handleSuplyChange = (event) => {
    const { value, checked } = event.target;

    setsuplyLocker((prevSelectedVideos) => (checked ? true : false));
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };

  const [isActive, setIsActive] = useState(false);

  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";

  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard mt-5">
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link onClick={() => navigate(-1)}>
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div className="user-back-det">
                              <h3>Create Locker</h3>
                              <p>
                                Enter the credentials to add new locker to the
                                system
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <div className="label-wrap">
                                  <h4>locker no</h4>
                                  <p>Enter the precise no of locker</p>
                                </div>
                              </div>
                              <div className="col-xl-8 col-lg-8">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="number"
                                    name="number"
                                    placeholder="LS-101"
                                    value={credentials.number}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>

                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <div className="label-wrap">
                                  <h4>Port</h4>
                                  <p>Enter the port number of locker</p>
                                </div>
                              </div>
                              <div className="col-xl-8 col-lg-8">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="port"
                                    name="port"
                                    placeholder=""
                                    value={credentials.port}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-4 col-lg-4">
                                <div className="label-wrap">
                                  <h4>Relay#</h4>
                                  <p>Enter the relay number of locker</p>
                                </div>
                              </div>
                              <div className="col-xl-8 col-lg-8">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="relay"
                                    name="relay"
                                    placeholder=""
                                    value={credentials.relay}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>

                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">
                              <button
                                type="submit"
                                disabled={isLoading}
                                href=""
                                className="btn btn-prim w-auto ms-5"
                              >
                                <i
                                  className="fa fa-spinner fa-spin"
                                  style={iocnStyle}
                                ></i>{" "}
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Add;
