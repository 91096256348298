import React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import Header from "../partials/VAHeader";
// Utils
const ExternalRoutes = ({ children }) => {
  const authed = localStorage.getItem("externalUser");
  const docgouser_token = localStorage.getItem("apiToken");
  if (authed || docgouser_token) {
    return (
      <>
        {/* <Header /> */}
        <Outlet />
      </>
    );
  } else {
    return <Navigate to="/" replace />;
  }
};
//return authed ? children : <Navigate to="/" replace /> };
export default ExternalRoutes;
