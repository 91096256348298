import "./App.css";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Header from "./Components/partials/Header";
import Code from "./Components/Code";
import Signup from "./Components/auth/Signup";
import AdminRoutes from "./Components/auth/AdminRoutes";
import PrivateRoute from "./Components/auth/PrivateRoute";
import PrivateRoutePin from "./Components/auth/PrivateRoutePin";
import ExternalRoutes from "./Components/auth/ExternalRoutes";
import SiteList from "./Components/site/Site";
import Add from "./Components/site/Add";
import Edit from "./Components/site/Edit";
import Lockers from "./Components/Lockers/List";
import LockersEdit from "./Components/Lockers/Edit";
import Profile from "./Components/profile/Profile";
import EditProfile from "./Components/profile/EditProfile";
import VaHeader from "./Components/partials/VAHeader";
import CallHeader from "./Components/partials/CallHeader";
import AdminPage from "./Components/pin_code/AdminPage";
import LoginPage from "./Components/pin_code/LoginPage";
import SiteUser from "./Components/pin_code/SiteUser";
import ResetPassword from "./Components/pin_code/ResetPassword";
import ForgotPassword from "./Components/pin_code/ForgotPassword";
import AdminLoginPage from "./Components/pin_code/AdminLoginPage";
import Logo from "./Components/site/Logo";

import DashboardScr from "./Components/dashboard/DashboardScr";
import VideoChat from "./Components/dashboard/VideoChat";
import Dashboardcopy from "./Components/dashboard/Dashboardcopy";
import InvidDashboard from "./Components/dashboard/InvidDashboard";
import VADashboard from "./Components/dashboard/VADashboard";
import VAProperty from "./Components/dashboard/VAProperty";
import VADashboard1 from "./Components/dashboard/VADashboard1";
import TimeScr from "./Components/dashboard/TimeScr";
import InActivity from "./Components/dashboard/InActivity";
import NewDashboard from "./Components/dashboard/NewDashboard";
import LiveStreaming2 from "./Components/dashboard/LiveStreaming2";
import VideoChatting from "./Components/dashboard/VideoChatting";
import AdminVideoChatting from "./Components/dashboard/AdminVdoChat";
import List from "./Components/netflix/List";
import UserList from "./Components/external/users/List";
import UserAdd from "./Components/external/users/Add";
import UserEdit from "./Components/external/users/Edit";
import ExternalUser from "./Components/partials/ExternalUser";
import ExternalUserAdd from "./Components/external/dasboard/user/Add";
import ExternalDashboard from "./Components/external/dasboard/Dasboard";
import ExternalUsers from "./Components/external/dasboard/user/Users";
import ExternalSites from "./Components/external/dasboard/site/Sites";
import ExternalSiteAdd from "./Components/external/dasboard/site/Add";
import ExternalSiteEdit from "./Components/external/dasboard/site/Edit";
import EmailConfigration from "./Components/external/dasboard/email_configration/List";
import EmailConfigrationAdd from "./Components/external/dasboard/email_configration/Add";
import EmailConfigrationEdit from "./Components/external/dasboard/email_configration/Edit";
import ApiAccess from "./Components/external/dasboard/api_access/List";
import LockersList from "./Components/external/dasboard/lcokers/List";
import LockersAdd from "./Components/external/dasboard/lcokers/Add";
import SiteLockersEdit from "./Components/external/dasboard/lcokers/Edit";
import LockerHistory from "./Components/external/dasboard/locker_history/List";
// import ApiAccessAdd from "./Components/external/dasboard/api_access/Add";
// import ApiAccessEdit from "./Components/external/dasboard/api_access/Edit";
import Call from "./Components/call/Call";

import { useSelector } from "react-redux";
import ExternalUserEdit from "./Components/external/dasboard/user/Edit";
function App() {
  const isAuthenticated = useSelector((state) => state.user);
  useEffect(() => {
    // const handleTouchMove = (e) => {
    //   if (e.touches.length === 2) {
    //     e.preventDefault();
    //   }
    // };
    // document.addEventListener("touchmove", handleTouchMove, { passive: false });
    // return () => {
    //   document.removeEventListener("touchmove", handleTouchMove);
    // };
  }, []);
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<SiteUser />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/code/:slug" element={<Code />} />
          <Route exact path="/:slug/pin" element={<LoginPage />} />
          <Route
            exact
            path="/:slug/admin/pin"
            element={
              <>
                <Header />
                <AdminLoginPage />
              </>
            }
          />
          <Route exact path="/dashboard" element={<Dashboardcopy />} />
          <Route exact path="/:slug/newdashboard" element={<NewDashboard />} />
          <Route exact path="/video" element={<VideoChat />} />
          <Route exact path="/:slug/videochat" element={<VideoChatting />} />
          <Route
            exact
            path="/:slug/call"
            element={
              <>
                <CallHeader />
                <Call />
              </>
            }
          />
          <Route
            exact
            path="/:slug/VA"
            element={
              <>
                <ExternalUser />
                {isAuthenticated &&
                  (isAuthenticated?.type === "sub" ||
                    isAuthenticated?.type === "main") && (
                    <>
                      <VaHeader />
                      <VADashboard />
                    </>
                  )}
              </>
            }
          />
          <Route
            exact
            path="/:slug/VA/property/:id"
            element={
              <>
                <VaHeader />
                <VAProperty />
              </>
            }
          />
          <Route
            exact
            path="/:slug/va/:newslug"
            element={
              <>
                <ExternalUser />
                {isAuthenticated &&
                  (isAuthenticated?.type === "sub" ||
                    isAuthenticated?.type === "main") && (
                    <>
                      <VaHeader />
                      <VADashboard />
                    </>
                  )}
              </>
            }
          />
          <Route
            exact
            path="/live/streaming/:id"
            element={<LiveStreaming2 />}
          />
          <Route
            exact
            path="/:slug/adminvideochat"
            element={<AdminVideoChatting />}
          />
          <Route exact path="/admin" element={<AdminPage />} />
          <Route exact path="/forgot/password" element={<ForgotPassword />} />
          <Route
            exact
            path="/reset/password/:token"
            element={<ResetPassword />}
          />

          {/* Invid Routes */}
          <Route element={<PrivateRoutePin />}>
            <Route
              exact
              path="/:slug/dashboard1"
              element={<InvidDashboard />}
            />
          </Route>

          {/* User Routes */}
          <Route element={<ExternalRoutes />}>
            <Route exact path="/va/dashboard" element={<ExternalDashboard />} />
            <Route
              exact
              path="/:slug/va/:newslug/dashboard"
              element={<ExternalDashboard />}
            />
            <Route exact path="/va/users" element={<ExternalUsers />} />
            <Route exact path="/va/sites" element={<ExternalSites />} />
            <Route exact path="/va/site/add" element={<ExternalSiteAdd />} />
            <Route
              exact
              path="/va/sites/edit/:id"
              element={<ExternalSiteEdit />}
            />
            <Route exact path="/va/:slug/lockers" element={<LockersList />} />
            <Route
              exact
              path="/va/:slug/lockers/add"
              element={<LockersAdd />}
            />
            <Route exact path="/va/user/add" element={<ExternalUserAdd />} />
            <Route
              exact
              path="/va/users/edit/:id"
              element={<ExternalUserEdit />}
            />
            <Route
              exact
              path="/va/:slug/lockers/edit/:id"
              element={<SiteLockersEdit />}
            />
            <Route
              exact
              path="/va/email/configration"
              element={<EmailConfigration />}
            />
            <Route
              exact
              path="/va/email/configration/add"
              element={<EmailConfigrationAdd />}
            />
            <Route
              exact
              path="/va/email/configration/edit/:id"
              element={<EmailConfigrationEdit />}
            />
            <Route
              exact
              path="/va/locker/history"
              element={<LockerHistory />}
            />
            <Route exact path="/va/api/access" element={<ApiAccess />} />
            <Route exact path="*" element={<Navigate to="/va/sites" />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route exact path="/:slug/dashboard" element={<DashboardScr />} />
            <Route exact path="/:slug/timer" element={<TimeScr />} />
            <Route exact path="inActivity" element={<InActivity />} />
          </Route>
          <Route element={<AdminRoutes />}>
            <Route exact path="/:slug/lockers/" element={<Lockers />} />
            <Route exact path="/sites" element={<SiteList />} />
            <Route exact path="/site/add" element={<Add />} />
            <Route exact path="/site/logo/add" element={<Logo />} />
            <Route exact path="/edit/site/:id" element={<Edit />} />
            <Route exact path="/edit/locker/:id" element={<LockersEdit />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/profile/edit" element={<EditProfile />} />
            <Route exact path="/netflix" element={<List />} />
            <Route exact path="/user/pin" element={<UserList />} />
            <Route exact path="/user/pin/add" element={<UserAdd />} />
            <Route exact path="/user/pin/edit/:id" element={<UserEdit />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}
export default App;
