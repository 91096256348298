import React, { useEffect, useState } from "react";
import Header from "../../../Components/partials/Header2";
import Sidebar from "../../../Components/partials/Sidebar";
import BackIcon from "../../../images/icon-back.png";

import styles from "./style.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calender.css";
const localizer = momentLocalizer(moment);

const Edit = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();

  useEffect(() => {
    getExternalUser(id);
  }, [id]);
  const [credentials, setCredentials] = useState({
    email: "",
    pin: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInput = (e) => {
    let { name, value } = e.target;

    // Convert 12-hour format (AM/PM) to 24-hour format (if necessary)
    if (name === "fromTime" || name === "toTime") {
      const [hours, minutes] = value.split(":");
      const formattedTime = `${String(hours).padStart(2, "0")}:${minutes}`; // Ensures 24-hour format
      setCredentials({ ...credentials, [name]: formattedTime });
    } else {
      setCredentials({ ...credentials, [name]: value });
    }
  };
  const handleInputPin = (e) => {
    let { name, value } = e.target;

    // Remove non-numeric characters and limit to 6 digits
    value = value.replace(/\D/g, "").slice(0, 6);

    setCredentials((prev) => ({ ...prev, [name]: value }));
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const authToken = localStorage.getItem("token");
      headers.append("auth-token", authToken);
      setIsLoading(true);
      const response = await fetch(`${url}/api/external/update/${id}`, {
        mode: "cors",
        method: "PUT",
        headers: headers,
        body: JSON.stringify({
          email: credentials.email,
          pin: credentials.pin,
          events: events,
          permissions: permissions,
          status: credentials.status,
        }),
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        toast.success("External User Updated Successfully");
      } else {
        setIsLoading(false);
        toast.error(json.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // get site data
  // get role
  const getExternalUser = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const authToken = localStorage.getItem("token");
      headers.append("auth-token", authToken);
      const response = await fetch(`${url}/api/external/get/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      const formattedEvents =
        json?.externalUser?.events?.map((event) => ({
          ...event,
          start: new Date(event.start), // Convert string to Date object
          end: new Date(event.end), // Convert string to Date object
        })) || [];

      setEvents(formattedEvents);
      const { events, permissions, ...restUserData } = json.externalUser;

      setCredentials(restUserData);
      setPermissions(permissions);
    } catch (error) {
      console.error(error);
    }
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const [events, setEvents] = useState([]);

  // Handle event creation
  const handleSelectSlot = ({ start, end }) => {
    const title = window.prompt("Enter Event Name");
    if (title) {
      setEvents([...events, { start, end, title }]);
    }
  };
  const handleSelectEvent = (event) => {
    if (window.confirm(`Delete event: "${event.title}"?`)) {
      setEvents(events.filter((e) => e !== event));
    }
  };
  const [permissions, setPermissions] = useState([]);

  const handlePermissionChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setPermissions([...permissions, value]);
    } else {
      setPermissions(permissions.filter((p) => p !== value));
    }
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header isActive={isActive} toggleActive={toggleActive} />
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link to="/user/pin">
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div className="user-back-det">
                              <h3>Update External User</h3>
                              <p>Update the details of the external user</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Email</h4>
                                  <p>Enter the email of the user</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="sitec"
                                    placeholder="Enter Email"
                                    name="email"
                                    value={credentials.email}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>

                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>User PIN</h4>
                                  <p>Enter the PIN of the user</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="number"
                                    placeholder="Enter PIN"
                                    name="pin"
                                    required
                                    value={credentials.pin}
                                    onChange={handleInputPin}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Status</h4>
                                  <p>Select the status of the user</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <select
                                    className="form-select"
                                    name="status"
                                    value={credentials.status}
                                    onChange={handleInput}
                                    required
                                  >
                                    <option value="">Select Status</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            {/* input row */}
                            <div className="row">
                              <div className="inpt-wrap">
                                <label>
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    value="lockers_ui"
                                    checked={permissions.includes("lockers_ui")}
                                    onChange={handlePermissionChange}
                                  />
                                  LOCKERS UI
                                </label>
                                <label>
                                  <input
                                    className=" me-2"
                                    type="checkbox"
                                    value="turnstiles_ui"
                                    checked={permissions.includes(
                                      "turnstiles_ui"
                                    )}
                                    onChange={handlePermissionChange}
                                  />
                                  TURNSTILES UI
                                </label>
                                <label>
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    value="temp_ui"
                                    checked={permissions.includes("temp_ui")}
                                    onChange={handlePermissionChange}
                                  />
                                  TEMP UI
                                </label>
                                <label>
                                  <input
                                    className=" me-2"
                                    type="checkbox"
                                    value="rtsp_streaming_ui"
                                    checked={permissions.includes(
                                      "rtsp_streaming_ui"
                                    )}
                                    onChange={handlePermissionChange}
                                  />
                                  RTSP STREAMING UI
                                </label>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="container mt-4">
                                <Calendar
                                  localizer={localizer}
                                  events={events}
                                  startAccessor="start"
                                  endAccessor="end"
                                  selectable
                                  onSelectSlot={handleSelectSlot} // Create event on slot selection
                                  onSelectEvent={handleSelectEvent} // Delete event on click
                                  style={{
                                    height: 500,
                                    background: "white",
                                    borderRadius: "8px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {/* From Date-Time */}

                          {/* From Time Picker */}

                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">
                              <a
                                href=""
                                className="btn btn-prim btn-transparent w-auto"
                              >
                                Reset Form
                              </a>
                              <button
                                type="submit"
                                className="btn btn-prim w-auto ms-5"
                                disabled={isLoading}
                              >
                                {isLoading ? "Updating..." : "Update User"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Edit;
