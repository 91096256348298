import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import login1 from "../../images/login1.svg";
import Logo from "../../assets/logo_black.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../Redux/Authentication";
const url = process.env.REACT_APP_APP_BACK_URL;

let headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Access-Control-Allow-Origin", "*");
headers.append("Access-Control-Allow-Credentials", "true");

const SiteUser = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [credentials, setCredentials] = useState({ name: "" });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
    const response = await fetch(`${url}/api/sites/check_site`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        slug: credentials.name,
      }),
    });
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      localStorage.setItem("siteuser", credentials.name);
      localStorage.setItem("site_logo", json.site_logo);
      if (!json.required_pin) {
        checkPinRequired(credentials.name);
        return;
      }
      localStorage.setItem("site_logo", json.site_logo);
      toast.success("Enter pin to login ");
      navigate(`/${credentials.name}/pin`);
    } else {
        toast.error(json.error);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      console.log(error);
    }
  };
  const getCurrentDate = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-based, so we add 1 to get the actual month
    const currentDay = currentDate.getDate();
    const user_code = currentYear + "" + currentMonth + "" + currentDay;

    return user_code;
  };
  const checkPinRequired = async (name) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);

      const response = await fetch(`${url}/api/auth/code`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          name: getCurrentDate(),
          slug: name,
        }),
      });
      setIsLoading(false);

      const json = await response.json();

      if (json.success) {
        const user = json.user;
        const expirationTime = Math.floor(Date.now() / 1000) + 7200;
        localStorage.setItem("token", json.authToken);
        localStorage.setItem("token-expiration", expirationTime);
        dispatch(loginSuccess(json.user));
        // toast.success(" Successfully login Successfully");
        if (user.role === "user") {
          navigate(`/${name}/dashboard1`);
        } else if (user.role === "admin") {
          navigate(`/${name}/timer`);
        } else {
          toast.error("Enter valid pin");
        }
      } else {
        toast.error(json.error);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      console.log(error);
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  useEffect(() => {
    const siteuser = localStorage.getItem("siteuser");
    (async () => {
      try {
        if (siteuser) {
          setCredentials({ ...credentials, name: siteuser });
          const response = await fetch(`${url}/api/sites/check_site`, {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            slug: siteuser,
          }),
        });
        const json = await response.json();

        if (json.success) {
          if (!json.required_pin) {
            checkPinRequired(siteuser);
            return;
          }
          toast.success("Enter pin to login ");
          navigate(`/${siteuser}/pin`);
        } else {
          toast.error(json.error);
          }
        }
      } catch (error) {
        toast.error(error.message);
        console.log(error);
      }
    })();
  }, []);

  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <div className="container-fluid px-0">
          <div className={styles.body_wrap}>
            <div className="login-container">
              <div className="image-container d-flex justify-content-center align-items-center mt-5">
                <img src={Logo} alt="login1" height={70} width={70} />
              </div>
              <form className="form-container" onSubmit={formSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    name="name"
                    placeholder="Enter Site User Name.."
                    value={credentials.name}
                    autoComplete="off"
                    onChange={handleInput}
                    required
                  />
                </div>
                <button
                  type="submit"
                  disabled={isLoading}
                  href=""
                  className="btn btn-prim"
                >
                  <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                  <span className="fw-700 fs-5">Enter</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SiteUser;
