import React, { useEffect, useState } from "react";
import Header from "../partials/Header2";
import Sidebar from "../partials/Sidebar";
import styles from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { useParams } from "react-router-dom";
import Images from "../modal/Images";

const List = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  let { slug } = useParams();

  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({});
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const [qrTypes, setQrTypes] = useState([]);

  const [site, setsite] = useState(isAuthenticated.site);
  const [role, setrole] = useState(isAuthenticated.role);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [credentials, setCredentials] = useState({ type: "all", name: "" });
  const handleInput = (e) => {
    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials((prevCredentials) => {
      const updatedCredentials = {
        ...prevCredentials,
        [e.target.name]: e.target.value,
      };
      fetchData(updatedCredentials);
      return updatedCredentials;
    });
  };
  useEffect(() => {
    fetchData(credentials);
    // getTypes();
  }, [currentPage]);

  const getTypes = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/type/qr/types`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });

      const json = await response.json();
      setQrTypes(json);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async (fetchCredentials) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);

      const response = await fetch(
        `${url}/api/sites/lockerList/${slug}?page=${currentPage}&limit=${itemsPerPage}&name=${fetchCredentials.name}&type=${fetchCredentials.type}&site=${site}&role=${role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.locker_data.length > 0) {
        setData(json.locker_data);
        setIsDisplay(true);
      } else {
        setIsNotFound(true);
      }
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");

        const response = await fetch(`${url}/api/sites/delete/locker/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        });

        if (response.ok) {
          fetchData(credentials); // Refresh the records list after successful deletion
          Swal.fire("Deleted!", "Locker  deleted successfully!", "success");
        } else {
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
      Swal.fire(
        "Error",
        "An error occurred while deleting the record.",
        "error"
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const openModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImageUrl("");
  };
  const formattedDate = (date) => {
    if (!date) {
      // If date is empty or null, use the current date
      date = new Date();
    }

    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const [isActive, setIsActive] = useState(false);

  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";

  const handleResetBtn = () => {
    const updatedCredentials = {
      name: "",
      type: "all",
    };

    // Update state and then call fetchData
    setCredentials(updatedCredentials);
    setCurrentPage(1);

    // Call fetchData with the updated credentials
    fetchData(updatedCredentials);
  };
  function toggleFilterSection() {
    setIsFilterVisible(!isFilterVisible);
  }

  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header isActive={isActive} toggleActive={toggleActive} />
                <div className="general-dashboard mt-3">
                  <div className="container-fluid">
                    <div className="dashboard-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap">
                            <h3>Lockers</h3>
                            <p>List of all the lockers in the system</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* row */}
                    <div className="table-wrap">
                      <div className="row">
                        <div className="col-12">
                          <div className={styles.card_box}>
                            <div className={styles.tables_wrap}>
                              <div className="table-responsive">
                                <table className="table align-middle">
                                  <thead>
                                    <tr>
                                      <th>Locker no</th>
                                      <th>Site</th>
                                      <th>Port</th>
                                      <th>Relay#</th>
                                      <th>Status</th>
                                      <th></th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {isLoading && (
                                      <>
                                        <tr>
                                          <td
                                            colSpan={4}
                                            className="text-center"
                                            style={{
                                              background: "transparent",
                                            }}
                                          >
                                            <HashLoader
                                              color={`#0b0d41`}
                                              loading={isLoading}
                                              size={40}
                                              aria-label="Loading Spinner"
                                              data-testid="loader"
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                    {isDisplay && data.length > 0
                                      ? data.map((item, i) => (
                                          <motion.tr
                                            key={item._id}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{
                                              duration: 1.2,
                                              delay: i * 0.1,
                                            }}
                                          >
                                            <td>
                                              <div className="usr_det_tb">
                                                <span>{item.number}</span>
                                              </div>{" "}
                                            </td>

                                            <td>{item.siteName}</td>
                                            <td>{item.port}</td>
                                            <td>{item.relay}</td>

                                            <td>
                                              {item.status &&
                                                item.status !== "" && (
                                                  <>
                                                    {item.status ===
                                                      "available" && (
                                                      <span className="status success">
                                                        Available
                                                      </span>
                                                    )}
                                                    {item.status ===
                                                      "occupied" && (
                                                      <span className="status warning">
                                                        Occupied
                                                      </span>
                                                    )}
                                                  </>
                                                )}
                                            </td>
                                            <td className="text-end">
                                              <Link
                                                to={`/edit/locker/${item._id}`}
                                              >
                                                <button
                                                  type="bu  tton"
                                                  className="btn btn-edit tb-btn"
                                                >
                                                  Edit
                                                </button>
                                              </Link>
                                              <button
                                                type="button"
                                                className="btn btn-delete tb-btn"
                                                onClick={() =>
                                                  handleDelete(item._id)
                                                }
                                              >
                                                Remove
                                              </button>
                                            </td>
                                          </motion.tr>
                                        ))
                                      : isNotFound && (
                                          <tr>
                                            <td
                                              colSpan={7}
                                              className="text-center"
                                            >
                                              <p className="text-center text-danger">
                                                No data found.
                                              </p>
                                            </td>
                                          </tr>
                                        )}
                                  </tbody>
                                </table>
                              </div>
                              <div className="table-num">
                                {data.length > 0 && (
                                  <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    pageCount={totalPages}
                                    forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                                    onPageChange={handlePageChange}
                                    containerClassName={" dig-num"}
                                    pageClassName={"dig-num"}
                                    previousClassName={"num-btns"}
                                    nextClassName={"num-btns"}
                                    disabledClassName={"pagination-disabled"}
                                    activeClassName={"pagination-active"}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default List;
