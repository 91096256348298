import React, { useEffect, useState } from "react";
import Header from "../../partials/VAHeader";
import Sidebar from "../../partials/SidebarExternal";
import styles from "./style.module.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "sweetalert2/dist/sweetalert2.min.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const List = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("apiToken");
  let { slug } = useParams();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [credentials, setCredentials] = useState({ status: "all", name: "" });
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";

  function toggleFilterSection() {
    setIsFilterVisible(!isFilterVisible);
  }
  const [data, setChartData] = useState([
    { month: "January", collected: 200, distributed: 150 },
    { month: "February", collected: 180, distributed: 130 },
    { month: "March", collected: 250, distributed: 190 },
    { month: "April", collected: 300, distributed: 220 },
    { month: "May", collected: 220, distributed: 170 },
  ])
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (fetchCredentials) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${apiUrl}/api/history/property`,
        {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json?.status) {
        setChartData(json?.monthlyData);
    
      } else {
        setChartData([]);
       
        toast.error(json?.message);
      }
    
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      console.error(error);
    }
  };

  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard mt-5">
                <div className="general-dashboard mt-3">
                  <div className="container-fluid">
                    <div className="dashboard-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap">
                            <h3>Dashboard</h3>
                            <p>List of all the lockers in the system</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* row */}
                  </div>
                  <div className="p-4 border rounded-lg shadow-md bg-white">
      <h2 className="text-lg font-bold mb-4">Property </h2>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="collected" fill="#4F46E5" name="Collected" barSize={40} />
          <Bar dataKey="distributed" fill="#EF4444" name="Distributed" barSize={40} />
        </BarChart>
      </ResponsiveContainer>
    </div>
                 
                 
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default List;
