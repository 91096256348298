import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import BackIcon from "../../../../images/icon-back.png";
import Sidebar from "../../../partials/SidebarExternal";
import styles from "./style.module.css";
const AddData = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("apiToken");
  const react_base_url = process.env.REACT_APP_BASE_URL;
  const isAuthenticated = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);

  const [credentials, setCredentials] = useState({
    host: "",
    port: "",
    user: "",
    password: "",
  });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch(`${apiUrl}/api/email/config`, {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        body: JSON.stringify({
          port: credentials.port,
          host: credentials.host,
          user: credentials.user,
          password: credentials.password,
        }),
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.status) {
        setCredentials({
          port: "",
          host: "",
          user: "",
          password: "",
        });
        toast.success("Added Successfully");
      } else {
        toast.error(json?.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      console.log(error);
    }
  };

  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard mt-5">
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link to="/va/email/configration">
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div className="user-back-det">
                              <h3>Create Communication Configuration</h3>
                              <p>
                                Enter the credentials to add new configuration
                                to the system
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Host</h4>
                                  <p>Enter the host of communication</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="host"
                                    placeholder="Host"
                                    name="host"
                                    value={credentials.host}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Port</h4>
                                  <p>Enter port of the communication</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="port"
                                    placeholder="Port"
                                    name="port"
                                    value={credentials.port}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>

                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>User</h4>
                                  <p>Enter the User of Authentication</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="user"
                                    placeholder="User"
                                    name="user"
                                    value={credentials.user}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Password</h4>
                                  <p>Enter password for Authentication</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Password"
                                    name="password"
                                    value={credentials.password}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">
                              <button
                                type="submit"
                                disabled={isLoading}
                                href=""
                                className="btn btn-prim w-auto ms-5"
                              >
                                <i
                                  className="fa fa-spinner fa-spin"
                                  style={iocnStyle}
                                ></i>{" "}
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default AddData;
