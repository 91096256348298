import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import ReactPaginate from "react-paginate";
import styles from "./style.module.css";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import Sidebar from "../../../partials/SidebarExternal";
// import Edit from "../../../../images/edit-ic.svg";
// import Delete from "../../../../images/delete-ic.svg";

const CommunicationList = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("apiToken");
  const [data, setData] = useState([]);
  const [getRoles, setRoles] = useState([]);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [searchValue, setSearchValue] = useState("");
  const [selectedRole, setSelectedRole] = useState("all");
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    fetchData();
  }, [currentPage, searchValue, selectedRole]);

  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);

      const response = await fetch(`${apiUrl}/api/email/config`, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.data.length > 0) {
        setIsDisplay(true);
      } else {
        setIsNotFound(true);
      }
      setData(json.data);
      //   setTotalPages(json.totalPages);
    } catch (error) {
      setIsNotFound(true);
      setIsDisplay(false);
      console.error(error);
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        Swal.showLoading();
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("auth-token", localStorage.getItem("token"));
        const response = await fetch(`${apiUrl}/api/email/config/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          fetchData(); // Refresh the records list after successful deletion
          Swal.fire("Deleted!", "User deleted successfully!", "success");
        } else {
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
      Swal.fire(
        "Error",
        "An error occurred while deleting the record.",
        "error"
      );
    }
  };

  const handelActive = async (iid) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));

      const response = await fetch(`${apiUrl}/api/email/config/status/${iid}`, {
        mode: "cors",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await response.json();
      if (json.status) {
        fetchData();
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard mt-5">
                <div className="general-dashboard mt-3">
                  <div className="container-fluid">
                    <div className="dashboard-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap">
                            <h3>All Communication</h3>
                            <p>
                              List of all the configurations added in the system
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end">
                          <div className="filter-right-sec">
                            <div className="filter-wrap">
                              {/* <div className="filter-box" onClick={toggleFilterSection}>
                    <img src={FilterIcon} alt="" />
                  </div> */}
                            </div>
                            <div className="d-flex flex-row gap-3">
                              <Link
                                to="/va/email/configration/add"
                                className="btn btn-prim w-auto"
                              >
                                Add New
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {isFilterVisible && (
            <>
              <motion.div
                className={`row mt-5 filter-section`}
                initial={{ opacity: 0 }} // Initial state (hidden)
                animate={{ opacity: isFilterVisible ? 1 : 0 }} // Animate opacity based on isFilterVisible
                transition={{ duration: 1.2, delay: 0.1 }}
              >
                <div className="col-12">
                  <div className="filter-right-sec filter-wrap-chat">
                    <div className="filter-select d-flex align-items-center">
                      <label htmlFor="">Role</label>
                      <select
                        name="role"
                        id=""
                        value={credentials.role}
                        onChange={handleInput}
                      >
                        <option value="all">Choose ROl</option>
                        {getRoles.map((per) => (
                          <option key={per._id} value={per.name}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="filter-select d-flex align-items-center">
                      <label htmlFor="">Site</label>
                      <select
                        name="site"
                        id=""
                        value={credentials.site}
                        onChange={handleInput}
                      >
                        <option value="all">Choose site</option>
                        {getSite.map((per) => (
                          <option key={per._id} value={per._id}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="filter-select d-flex align-items-center">
                      <label htmlFor="">Status</label>
                      <select
                        name="status"
                        id=""
                        value={credentials.status}
                        onChange={handleInput}
                      >
                        <option value="all">Choose Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                    <div className="search-wrap">
                      <input
                        type="search"
                        placeholder="search by username"
                        name="name"
                        value={credentials.name}
                        onChange={handleInput}
                      />
                      <div className="search-box">
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <Link
                      to=""
                      onClick={handleResetBtn}
                      className="btn btn-prim w-auto"
                    >
                      RESET
                    </Link>
                  </div>
                </div>
              </motion.div>
            </>
          )} */}
                    </div>
                    {/* row */}
                    <div className="table-wrap">
                      <div className="row">
                        <div className="col-12">
                          <div className={styles.card_box}>
                            <div className={styles.tables_wrap}>
                              <div className="table-responsive">
                                <table className="table align-middle">
                                  <thead>
                                    <tr>
                                      {/* <th>#</th> */}
                                      <th>Host</th>
                                      <th>Port</th>
                                      <th>User</th>
                                      <th>Status</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isLoading && (
                                      <>
                                        <tr>
                                          <td
                                            colSpan={6}
                                            className="text-center"
                                            style={{
                                              background: "transparent",
                                            }}
                                          >
                                            <HashLoader
                                              color={`#0b0d41`}
                                              loading={isLoading}
                                              size={40}
                                              aria-label="Loading Spinner"
                                              data-testid="loader"
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                    {isDisplay &&
                                      data.map((user, i) => (
                                        <motion.tr
                                          key={user._id}
                                          initial={{ opacity: 0 }}
                                          animate={{ opacity: 1 }}
                                          transition={{
                                            duration: 1.2,
                                            delay: i * 0.1,
                                          }}
                                        >
                                          <td>
                                            <div className="usr_det_tb">
                                              <span className="text-lowercase">
                                                {user.host}
                                              </span>
                                            </div>
                                          </td>
                                          <td>{user.port}</td>
                                          <td className="text-lowercase">
                                            {user.user}
                                          </td>
                                          <td>
                                            {user.status &&
                                              user.status !== "" && (
                                                <>
                                                  {user.status === "active" && (
                                                    <span className="status success">
                                                      Active
                                                    </span>
                                                  )}
                                                  {user.status ===
                                                    "deactive" && (
                                                    <span className="status danger">
                                                      Deactive
                                                    </span>
                                                  )}
                                                </>
                                              )}
                                          </td>

                                          <td>
                                            <div className="d-flex justify-content-end gap-1">
                                              {user.status === "deactive" ? (
                                                <button
                                                  type="button"
                                                  className="btn btn-success  w-auto m-1"
                                                  onClick={() => {
                                                    handelActive(user._id);
                                                  }}
                                                >
                                                  Active
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn btn-prim w-auto  m-1"
                                                  disabled
                                                >
                                                  Deactive
                                                </button>
                                              )}

                                              <button
                                                type="button"
                                                className="btn btn-delete tb-btn"
                                                onClick={() =>
                                                  handleDelete(user._id)
                                                }
                                              >
                                                Remove
                                                {/* <img src={Delete} alt="delete" /> */}
                                              </button>
                                            </div>
                                          </td>
                                        </motion.tr>
                                      ))}
                                    {isNotFound && (
                                      <tr>
                                        <td colSpan={7} className="text-center">
                                          No Data Found
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              {/* <div className="table-num">
                  <div className="dig-num">
                    <span>1</span> <Link to="/">2</Link>{" "}
                    <Link to="/">3</Link> <Link to="/">4</Link>{" "}
                  </div>
                  <div className="num-btns">
                    <Link to="/">
                      <img src={PreviousIcon} alt="" />
                    </Link>{" "}
                    <Link to="/">
                      <img src={NextIcon} alt="" />
                    </Link>
                  </div>
                </div> */}
                              <div className="table-num">
                                {data.length > 0 && (
                                  <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={"..."}
                                    pageCount={totalPages}
                                    forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                                    onPageChange={handlePageChange}
                                    containerClassName={" dig-num"}
                                    pageClassName={"dig-num"}
                                    previousClassName={"num-btns"}
                                    nextClassName={"num-btns"}
                                    disabledClassName={"pagination-disabled"}
                                    activeClassName={"pagination-active"}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default CommunicationList;
