import React, { useEffect, useState } from "react";
import Header2 from "../../../partials/Header2";
import Sidebar from "../../../partials/SidebarExternal";
import BackIcon from "../../../../images/icon-back.png";
// import UploadIcon from '../../../images/icon-upload.png'
import { useSelector } from "react-redux";
import styles from "./style.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
const Edit = () => {
  const { id } = useParams();
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const apiUrl = process.env.REACT_APP_API_URL;

  const [credentials, setCredentials] = useState({
    name: "",
    address: "",
    password: "",
    ip_address: "",
    locker_qty: "",
    slug: "",
    port: "",
    parent_site: "",
    pin: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  
  const handleInputSlug = (e) => {
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;

    // Check if the entered value matches the pattern
    if (alphanumericRegex.test(e.target.value) || e.target.value === "") {
      setCredentials({ ...credentials, [e.target.name]: e.target.value });
    }
  };
  
  const handleInputPin = (e) => {
    let { name, value } = e.target;

    // Remove non-numeric characters and limit to 6 digits
    value = value.replace(/\D/g, "").slice(0, 6);

    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const callApi = async () => {
    try {
      const token = localStorage.getItem("apiToken");
      const response = await fetch(`${apiUrl}/api/sites/${id}`, {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      const data = await response.json();
      setCredentials(data.site);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const token = localStorage.getItem("apiToken");
      const response = await fetch(`${apiUrl}/api/sites/${id}`, {
        mode: "cors",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        body: JSON.stringify({
          name: credentials.name,
          address: credentials.address,
          password: credentials.password,
          ip_address: credentials.ip_address,
          port: credentials.port,
          slug: credentials.slug,
          locker_qty: credentials.locker_qty,
          parent_site: credentials.parent_site,
          permissions: permissions,
          pin: credentials.pin,
        }),
      });

      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        setCredentials({
          name: "",
          address: "",
          slug: "",
          port: "",
          password: "",
          ip_address: "",
          locker_qty: "",
          parent_site: "",
          pin: "",
        });
        setPermissions([]);
        toast.success("Site Updated Successfully");
      } else {
        setIsLoading(false);
        toast.error(json.error);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const handlePermissionChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setPermissions([...permissions, value]);
    } else {
      setPermissions(permissions.filter((p) => p !== value));
    }
  };

  const divClassName = isActive ? "sidebar active" : "sidebar";

  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar  ${divClassName} `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <div className="general-dashboard mt-5">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link to="/va/sites">
                              <div className="back-icon">
                                <img src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div className="user-back-det">
                              <h3>Edit Site</h3>
                              <p>
                                Edit the site details
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <form onSubmit={formSubmit}>
                      <div className="form-box card-box">
                        <div className="row">
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site Name</h4>
                                  <p>Enter the name of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="sitec"
                                    placeholder="Site Name"
                                    name="name"
                                    value={credentials.name}
                                    onChange={handleInput}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site Slug</h4>
                                  <p>Enter the unique slug of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="sitec"
                                    placeholder="Site Slug"
                                    name="slug"
                                    value={credentials.slug}
                                    onChange={handleInputSlug}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site PIN</h4>
                                  <p>Enter the PIN of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="number"
                                    placeholder="Enter PIN"
                                    name="pin"
                                    required
                                    value={credentials.pin}
                                    onChange={handleInputPin}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>

                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site IP</h4>
                                  <p>Enter the IP address of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    placeholder="192.168.10.1"
                                    name="ip_address"
                                    required
                                    value={credentials.ip_address}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>

                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site Port</h4>
                                  <p>Enter the PORT address of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <input
                                    type="text"
                                    placeholder="5000"
                                    name="port"
                                    required
                                    value={credentials.port}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>

                          <div className="col-lg-6">
                            {/* input row */}
                            <div className="row">
                              <div className="col-xl-5 col-lg-5">
                                <div className="label-wrap">
                                  <h4>Site Address</h4>
                                  <p>Enter complete address of the site</p>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-7">
                                <div className="inpt-wrap">
                                  <textarea
                                    name="address"
                                    id=""
                                    cols="30"
                                    rows="3"
                                    value={credentials.address}
                                    onChange={handleInput}
                                  >
                                    2405 Wesbrook Mall, Vancouver, BC V6T 1Z4,
                                    Canada
                                  </textarea>
                                </div>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-12">
                            {/* input row */}
                            <div className="row">
                              <div className="inpt-wrap">
                                <label>
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    value="lockers_ui"
                                    onChange={handlePermissionChange}
                                  />
                                  LOCKERS UI
                                </label>
                                <label>
                                  <input
                                    className=" me-2"
                                    type="checkbox"
                                    value="turnstiles_ui"
                                    onChange={handlePermissionChange}
                                  />
                                  TURNSTILES UI
                                </label>
                                <label>
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    value="temp_ui"
                                    onChange={handlePermissionChange}
                                  />
                                  TEMP UI
                                </label>
                                <label>
                                  <input
                                    className=" me-2"
                                    type="checkbox"
                                    value="rtsp_streaming_ui"
                                    onChange={handlePermissionChange}
                                  />
                                  RTSP STREAMING UI
                                </label>
                              </div>
                            </div>
                            {/* input row */}
                          </div>
                          <div className="col-lg-12 text-end">
                            <div className="form-btn-wrap mb-5 pb-5">
                              <a
                                href=""
                                className="btn btn-prim btn-transparent w-auto"
                              >
                                Reset Form
                              </a>
                              <button
                                type="submit"
                                className="btn btn-prim w-auto ms-5"
                                disabled={isLoading}
                              >
                                {isLoading ? "Updating..." : "Update Site"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Edit;
