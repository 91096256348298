import React, { useEffect, useState, useRef } from "react";
import "../../simplelocker.css";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
import ExternalUser from "./ExternalUser";
import { toast } from "react-toastify";

import {
  checkFileExists,
  formattedDateTime1,
  formattedDateTime,
} from "../../utils/generic";
// import { io } from "socket.io-client";

const imageUrl = process.env.REACT_APP_APP_IMAGE_URL;
const url = process.env.REACT_APP_APP_BACK_URL;
const socket_url = process.env.REACT_APP_APP_SOCKET_URL;
// const socket = io.connect(socket_url);
let idleTimer;

const Header = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const isAuthenticated = useSelector((state) => state.user);
  let { slug } = useParams();
  const [stream, setStream] = useState();
  const [site, setSite] = useState({});
  const [siteLogo, setSiteLogo] = useState("");
  const [temparature, settemparature] = useState("66.0");
  const idleTimeoutInSeconds = 100; // 5 minutes (adjust as needed)
  useEffect(() => {
    siteData();
    if (
      slug.toLowerCase() === "docgo" &&
      localStorage.getItem("docgouser_token")
    ) {
      resetIdleTimer();
      const activityEvents = ["mousemove", "keydown", "mousedown", "scroll"];
      const onActivity = () => {
        resetIdleTimer();
      };
      activityEvents.forEach((event) => {
        window.addEventListener(event, onActivity);
      });
      return () => {
        activityEvents.forEach((event) => {
          window.removeEventListener(event, onActivity);
        });
      };
    }
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => {
      clearInterval(intervalId);
      // stopCamera();
    };
  }, []);
  useEffect(() => {
    if (
      isAuthenticated &&
      isAuthenticated?.permissions.includes("temp_ui") &&
      site
    ) {
      fetchData();

      // setStreamFun();
      if (isAuthenticated && isAuthenticated.role !== "super admin") {
        resetIdleTimer();
        const activityEvents = ["mousemove", "keydown", "mousedown", "scroll"];
        const onActivity = () => {
          resetIdleTimer();
        };
        activityEvents.forEach((event) => {
          window.addEventListener(event, onActivity);
        });
        return () => {
          activityEvents.forEach((event) => {
            window.removeEventListener(event, onActivity);
          });
        };
      }

      const intervalId = setInterval(() => {
        fetchData();
      }, 10000);

      return () => {
        // stopCamera();
        clearInterval(intervalId);
      };
    }
  }, [temparature, isAuthenticated, site]);

  useEffect(() => {
    // Update time every second
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);
  const resetIdleTimer = () => {
    clearTimeout(idleTimer);
    idleTimer = setTimeout(handle_logout1, idleTimeoutInSeconds * 1000);
  };
  const handle_logout = async (e) => {
    // if (site.required_pin) {
    localStorage.removeItem("apiToken");
    localStorage.removeItem("externalUser");
    dispatch(logout());
    window.location.href = `/${slug}/va`;
    // navigate(`/${slug}/va`);
    // setTimeout(() => {
    //   <ExternalUser isOpen={true} />;
    //   // navigate(`/${slug}/va`);
    // }, 2000);
    // }
  };
  const handle_logout1 = async (e) => {
    localStorage.removeItem("docgouser_token");

    window.location.href = `/${slug}/va`;
  };
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      let apiUrl = `${url}/api/sites/get-temperature?ip=${site?.ip_address}:${site?.port}`;

      const response = await fetch(apiUrl, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });

      const json = await response.json();
      if (json?.success) {
        settemparature(json?.data);
      }
    } catch (error) {}
  };

  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/sites/getSite/${slug}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSite(json.site);

        setSiteLogo(imageUrl + json.site.image);
      } else {
        toast.error("No site found! Enter valid site");
      }
    } catch (error) {
      console.error(error);
    }
  };
  // const handleLogout = () => {
  //   localStorage.removeItem("token");

  //   dispatch(logout());
  // };
  const menuRef = useRef(null);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <header className="header">
        <div className="row mb-2">
          <div className="prof-sett">
            <div className="col-4 d-flex justify-content-center ">
              <div className="main-heading">
                <div className="locker-logo">
                  <div className="locker-style-image d-flex justify-content-center ">
                    <img
                      className="logo-image-style img-fluid mx-auto"
                      src={siteLogo}
                      onError={(e) => {
                        e.target.src = "/locker_logo.png";
                      }}
                      alt="Logo"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-4 d-flex justify-content-center ">
              {slug.toLowerCase() !== "demo1" && (
                <h3 className="fw-700 bg-white p-2 rounded shadow-sm">
                  {temparature}°F
                </h3>
              )}
            </div> */}
            <div className="col-4 d-flex justify-content-center ">
              {isAuthenticated &&
                isAuthenticated?.permissions.includes("temp_ui") && (
                  <h3 className="fw-700 bg-white p-2 rounded shadow-sm">
                    {temparature}°F
                  </h3>
                )}
            </div>

            <div className="col-4 d-flex justify-content-center px-3">
              <div className="locker-timer position-relative" ref={menuRef}>
                <p>{formattedDateTime(currentDateTime)}</p>
                <p>{formattedDateTime1(currentDateTime)}</p>

                {/* Dropdown Navigation */}
                <div className="dropdown">
                  <button
                    className="bg-white px-3 dropdown-toggle"
                    style={{
                      border: "1px solid #012267",
                      borderRadius: "4px",
                      textDecoration: "none",
                      color: "black",
                    }}
                    onClick={() => setShowMenu(!showMenu)}
                  ></button>

                  {showMenu && (
                    <div className="dropdown-menu show position-absolute">
                      {!localStorage.getItem("docgouser_token") &&
                      isAuthenticated ? (
                        <>
                          <button
                            className="dropdown-item"
                            onClick={() => {
                              const url =
                                isAuthenticated?.type === "sub"
                                  ? `/va/sites`
                                  : `/va/sites`;

                              window.open(url, "_blank"); // Opens in a new tab
                            }}
                          >
                            Dashboard
                          </button>

                          <button
                            className="dropdown-item text-danger"
                            onClick={handle_logout}
                          >
                            Logout
                          </button>
                        </>
                      ) : (
                        <button
                          className="dropdown-item text-danger"
                          onClick={handle_logout1}
                        >
                          Logout
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header;
